import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import LandingPage from "./components/LandingPage/LandingPage";
import Blog from "./components/Blog/Blog";

import PublicForm from "./components/App/FormBuilder/PublicForm";
import FormBuilder from "./components/App/FormBuilder/FormBuilder";
import WorkflowBuilder from "./components/App/WorkflowBuilder/WorkflowBuilder";
import Settings from "./components/App/Settings/Settings";
import ProjectMenu from "./components/App/Projects/ProjectMenu";
import Contacts from "./components/App/Contacts/Contacts";
import MrPancake from "./components/App/MrPancake/MrPancake";
import YC from "./components/YC/YC";

import Sidenav from "./components/App/Sidenav/Sidenav";
import GalleryMenu from "./components/App/Delivery/GalleryMenu";
import Pricing from "./components/LandingPage/Pricing/Pricing";
import PrivacyPolicy from "./components/LandingPage/PrivacyPolicy";

import PublicGallery from "./components/App/Delivery/PublicGallery/PublicGallery";
import FormMenu from "./components/App/FormBuilder/FormMenu";

import ReactGA from "react-ga4";

import "@fontsource/roboto";
import Gallery from "./components/App/Delivery/GalleryEditor";
import WorkflowMenu from "./components/App/WorkflowBuilder/WorkflowMenu";
import ProjectDetails from "./components/App/Projects/Project/ProjectDetails";
// import { usePageLeavePrompt } from "./components/App/Utils/usePageLeavePrompt";

// import { useNavigate } from "react-router-dom";
function templateWithLandingPage(Component) {
  return (
    <>
      <Navbar />
      <Component />
    </>
  );
}

function templateWithApp(Component) {
  // const navigate = useNavigate();
  return (
    <>
      <div className="flex ">
        <Sidenav />
        <div className="w-full mt-20  ">
          <div className="w-5/6 mx-auto pb-16">
            <Component />
          </div>
        </div>
      </div>
    </>
  );
}

function App() {
  const TRACKING_ID = "G-73JGYFFE9F";
  ReactGA.initialize(TRACKING_ID);
  // ReactGA.send(document.location.pathname);
  // usePageLeavePrompt("Leave screen?");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={templateWithLandingPage(LandingPage)} />
          {/* <Route path="/" element={templateWithLandingPage(LoginPage)} /> */}
          {/* <Route path="/login" element={templateWithLandingPage(Login)} /> */}
          <Route path="/pricing" element={templateWithLandingPage(Pricing)} />
          <Route path="/yc" element={templateWithLandingPage(YC)} />
          {/* <Route path="/test" element={<A />} /> */}
          <Route
            path="/privacy-policy"
            element={templateWithLandingPage(PrivacyPolicy)}
          />
          <Route path="/blog" element={templateWithLandingPage(Blog)} />

          <Route path="/:username/forms/:formId" element={<PublicForm />} />
          <Route path="/:username/gallery/:id" element={<PublicGallery />} />

          <Route path="/app/home" element={templateWithApp(ProjectMenu)} />
          <Route path="/app/mrPancake" element={templateWithApp(MrPancake)} />
          <Route path="/app/contacts" element={templateWithApp(Contacts)} />
          <Route path="/app/forms" element={templateWithApp(FormMenu)} />
          <Route
            path="/app/forms/builder"
            element={templateWithApp(FormBuilder)}
          />
          <Route
            path="/app/forms/builder/:formId"
            element={templateWithApp(FormBuilder)}
          />
          <Route
            path="/app/workflows"
            element={templateWithApp(WorkflowMenu)}
          />
          <Route
            path="/app/workflows/builder"
            element={templateWithApp(WorkflowBuilder)}
          />
          <Route
            path="/app/workflows/builder/:workflowId"
            element={templateWithApp(WorkflowBuilder)}
          />
          <Route path="/app/gallery" element={templateWithApp(GalleryMenu)} />
          <Route path="/app/gallery/:id" element={templateWithApp(Gallery)} />
          <Route
            path="/app/project/:projectId"
            element={templateWithApp(ProjectDetails)}
          />
          <Route path="/app/settings" element={templateWithApp(Settings)} />
          <Route
            path="/*"
            element={<p className="text-7xl">404 not found</p>}
          />
        </Routes>
        {/* </Container> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
