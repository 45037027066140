import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import EmptyGallery from "../PhotosUploader";

import ShareGalleryPopup from "../ShareGalleryPopup";
import { ReactComponent as ChevronIcon } from "../../../../assets/icons/chevron.svg";
import GallerySettings from "../GallerySettings";
import Hero from "./Hero";
import PhotoGrid from "./PhotoGrid";

import Topnav from "./Topnav";

const PublicGallery = () => {
  const { id } = useParams();

  const [refresh, setRefresh] = useState(true);

  const [delivery, setDelivery] = useState({ contents: [] });

  const [user, setUser] = useState({});

  const [showPopup, setShowPopup] = useState(false);

  const [scenesSaved, setScenesSaved] = useState(true);

  const username = useParams().username;

  useEffect(() => {
    const getGallery = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/delivery/getFolder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, id: id }),
        }
      );
      const data = await res.json();
      console.log("data2", data);
      setDelivery(data.message.delivery);
      // user is the business
      setUser(data.message.user);
    };
    getGallery();
  }, [refresh]);

  const uploadPhotos = () => {
    console.log("upload photos");
  };

  const navigate = useNavigate();

  console.log("this is delivery2", delivery);

  return (
    <div className="w-full">
      <Hero delivery={delivery} user={user} />

      <Topnav username={username} id={id} user={user} delivery={delivery} />
      <PhotoGrid delivery={delivery} />
    </div>
  );
};

export default PublicGallery;
