import React from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const AddOptionsPopup = ({ addField, setPopupIndex, fieldIndex }) => {
  return (
    <div className="w-full  bg-gray-50">
      <div className="flex justify-end bg-gray-200 px-2 py-2">
        <button
          className="float-right"
          onClick={() => setPopupIndex(null)}
        ></button>
        <CrossIcon
          stroke="black"
          width={35}
          height={35}
          className="float-right cursor-pointer"
          onClick={() => setPopupIndex(null)}
        />
      </div>
      <div className="flex gap-2 justify-center pt-10 pb-10">
        <br />
        <div
          className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
          onClick={() => addField("textfield", fieldIndex)}
        >
          <p>Text</p>
        </div>
        <div
          className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
          onClick={() => addField("numberfield", fieldIndex)}
        >
          <p>Number</p>
        </div>
        <div
          className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
          onClick={() => addField("datefield", fieldIndex)}
        >
          <p>Date</p>
        </div>
        <div
          className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
          onClick={() => addField("textareafield", fieldIndex)}
        >
          <p>Long Text</p>
        </div>
      </div>
    </div>
  );
};

export default AddOptionsPopup;
