import React from "react";
import Hero from "./Hero";
import FeaturesSection from "./FeaturesSection";
import "../LandingPage/LandingPage.css";
import Testimonial from "./Testimonial";
import BusinessTools from "./BusinessTools";
import JoinUs from "./JoinUs";
import Footer from "./Footer";
const LandingPage = () => {
  return (
    <div>
      <Hero />
      <FeaturesSection />
      <Testimonial />
      <BusinessTools />
      <JoinUs />
      <Footer />
    </div>
  );
};

export default LandingPage;
