import React, { useState, useContext } from "react";

import CoverPhotoPopup from "./CoverPhotoPopup";
import moment from "moment";
const GallerySettings = ({
  folderId,
  delivery,
  setDelivery,
  setRefresh,
  setScenesSaved,
  setPhotosChanged,
}) => {
  const [showCoverPhotoPopup, setShowCoverPhotoPopup] = useState(false);

  const username = JSON.parse(localStorage.getItem("user")).username;

  return (
    <div className="flex flex-col gap-6">
      {showCoverPhotoPopup && (
        <CoverPhotoPopup
          folderId={folderId}
          setShowCoverPhotoPopup={setShowCoverPhotoPopup}
          setRefresh={setRefresh}
        />
      )}
      <h1 className="text-2xl font-semibold">Settings</h1>

      <div className="flex flex-col gap-2">
        <p>Cover photo</p>
        {delivery.coverPhoto ? (
          <img
            alt="cover"
            src={delivery.coverPhoto}
            className="w-[200px] border-2 rounded-lg"
          />
        ) : (
          <div
            className="flex justify-center items-center w-[100px] h-[100px]
         bg-gray-100 border-2 rounded-lg cursor-pointer"
            onClick={() => setShowCoverPhotoPopup(true)}
          >
            {/* <div className="w-[50px] h-[50px] bg-black"></div> */}
            <div className="text-5xl text-gray-400 -mt-1">+</div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        <p>Cover Title</p>

        <input
          type="text"
          // onChange={(e) => setBusinessName(e.target.value)}
          // value={businessName}

          className="w-fit py-1 border-gray-300 rounded-lg text-gray-500
               focus:ring-0 border-2
               pl-2"
          value={delivery?.settings?.folderName}
          onChange={(e) => {
            setDelivery((prevDelivery) => {
              var newDelivery = { ...prevDelivery };
              newDelivery.settings.folderName = e.target.value;
              return newDelivery;
            });
            setScenesSaved(false);
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        <p>Display Date: </p>

        <input
          type="date"
          // onChange={(e) => setBusinessName(e.target.value)}

          className="w-fit py-1 border-gray-300 rounded-lg text-gray-500
               focus:ring-0 border-2
               pl-2"
          value={moment.utc(delivery?.settings?.date).format("YYYY-MM-DD")}
          onChange={(e) => {
            setDelivery((prevDelivery) => {
              var newDelivery = { ...prevDelivery };
              newDelivery.settings.date = e.target.value;
              return newDelivery;
            });
            setScenesSaved(false);
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <p>Should we watermark the photos?</p>
        <div className="flex gap-2">
          <input
            type="radio"
            checked={delivery?.settings?.isWatermarked}
            name="watermark"
            onClick={() => {
              setDelivery((prevDelivery) => {
                var newDelivery = { ...prevDelivery };
                newDelivery.settings.isWatermarked = true;
                return newDelivery;
              });
              setScenesSaved(false);
              setPhotosChanged(true);
            }}
          />{" "}
          Watermark
        </div>
        <div className="flex gap-2">
          <input
            type="radio"
            checked={!delivery?.settings?.isWatermarked}
            name="watermark"
            onClick={(e) => {
              setDelivery((prevDelivery) => {
                var newDelivery = { ...prevDelivery };
                newDelivery.settings.isWatermarked = false;
                return newDelivery;
              });
              setScenesSaved(false);
              setPhotosChanged(true);
            }}
          />{" "}
          No watermark
        </div>
      </div>
      <div className="flex gap-2 -mt-5">
        <button
          className="bg-brown text-sm hover:bg-beach font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
          // onClick={submit}
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_FRONTEND_URL}/${username}/gallery/${folderId}`,
              "_blank"
            )
          }
        >
          Preview
        </button>
        {/* <button
          className="bg-brown text-sm hover:bg-beach font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
          // onClick={submit}
        >
          Share
        </button> */}
      </div>
    </div>
  );
};

export default GallerySettings;
