import React, { useState } from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const AddEmailsPopup = ({
  workflowEmails,
  setWorkflowEmails,
  setShowAddEmailsPopup,
}) => {
  return (
    <div className=" bg-gray-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40">
      <div>
        <div className="flex justify-end bg-gray-200 px-2 py-2">
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer"
            onClick={() => {
              setShowAddEmailsPopup(false);
            }}
          />
        </div>
        <div className="flex flex-col gap-2 justify-center px-10 py-10">
          <p className="font-semibold mb-2">Add an email</p>
          <p className="mb-2">Save the workflow, when done adding the emails</p>

          {workflowEmails.map((el, index) => (
            <div>
              <input
                type="text"
                value={el}
                className="px-2 py-1 rounded-lg"
                onChange={(e) =>
                  setWorkflowEmails((prevEmails) => {
                    const newEmails = [...prevEmails];
                    newEmails[index] = e.target.value;
                    return newEmails;
                  })
                }
              />
            </div>
          ))}

          <button
            onClick={() =>
              setWorkflowEmails((prevEmails) => [...prevEmails, ""])
            }
            className="bg-brown px-2 py-1 rounded-lg w-fit ml-auto"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmailsPopup;
