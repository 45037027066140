import React from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
const ContactPopup = ({ plan, setPlan }) => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [emailSubmitError, setEmailSubmitError] = React.useState(false);

  const contactUs = async () => {
    if (!email.includes("@") || !email.includes(".")) {
      setMessage("Please enter a valid email address");
      setEmailSubmitError(true);
    } else {
      console.log(
        "this 2",
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/addToContactUs`
      );
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/addToContactUs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, plan: plan }),
        }
      );
      const data = await res.json();
      console.log("this is the data", data);
      setMessage(data.message);
      setEmailSubmitError(false);
    }
  };

  return (
    <div className="bg-gray-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
      <div className="flex justify-end bg-gray-200 px-2 py-2 rounded-t-md">
        <button className="float-right"></button>
        <CrossIcon
          stroke="black"
          width={35}
          height={35}
          className="float-right cursor-pointer"
          onClick={() => setPlan(null)}
        />
      </div>
      <div className="flex flex-col gap-0 justify-center px-20 py-10">
        <p className="text-2xl text-primary font-semibold">
          Please enter your email
        </p>
        <p className="text-base text-secondary">
          We will get back to you as soon as possible about the {plan} plan.
        </p>
        <input
          type="text"
          className="mt-8 border-2 rounded-lg py-1 text-xl"
          onChange={(e) => setEmail(e.target.value)}
        />
        <p className={emailSubmitError ? "text-red-500" : "text-green-500"}>
          {message}
        </p>
        <button
          className="bg-brown hover:bg-beach text-black font-medium rounded-lg w-fit px-4 py-2 mt-2"
          onClick={contactUs}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ContactPopup;
