import React, { useContext, useState } from "react";
import { GoogleButton } from "react-google-button";

import { useNavigate } from "react-router-dom";

const Slide2 = ({}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  function connectToGoogle() {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/google/redirectToOAuth2`;
  }
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-10 w-full items-center justify-center">
      {/* <div> */}
      <h1 className="text-3xl text-center">Welcome to PancakeCRM</h1>
      <p className="text-lg">Please connect your account with Google</p>
      <p>
        Please use the email <span className="text-purple">{user.email}</span>{" "}
        when signing in
      </p>
      <div className="flex flex-col gap-6">
        {/* <p className="text-red-600">{message}</p> */}
        {/* TODO: add a check that if refresh_token is valid, only then allow the button to be clicked */}

        <GoogleButton
          type="light" // can be light or dark
          label="Connect with Google"
          onClick={connectToGoogle}
        />
      </div>
      <div className="flex gap-2">
        {/* <button
          className="bg-brown hover:bg-beach text-black self-center text-sm hover:bg-primary font-semibold text-white rounded-lg w-fit px-4 py-2 mt-2 "
          // onClick={finishOnboarding}
          // refresh the page when they hit finish
          onClick={() => navigate(0)}
        >
          Finish
        </button> */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Slide2;
