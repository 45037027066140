import React, { useEffect, useState } from "react";
import NavItem from "./NavItem";

import { ReactComponent as HomeIcon } from "../../../assets/icons/App/Sidenav/home.svg";
import { ReactComponent as FormIcon } from "../../../assets/icons/App/Sidenav/form.svg";
import { ReactComponent as WorkflowIcon } from "../../../assets/icons/App/Sidenav/workflow.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/App/Sidenav/settings.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icons/App/Sidenav/image.svg";
import { ReactComponent as ProjectsIcon } from "../../../assets/icons/projects.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg";
import { ReactComponent as PancakeIcon } from "../../../assets/icons/pancake-logo-bw.svg";

import { useNavigate } from "react-router-dom";

const Sidenav = () => {
  const [selected, setSelected] = useState(0);
  const [businessName, setBusinessName] = useState("");
  const [logo, setLogo] = useState("");
  const username = JSON.parse(localStorage.getItem("user")).username;

  useEffect(() => {
    // deadass do this for every useEffect that requires username
    // because it takes time for the username to be defined in the useEffect in
    // AppDetailsProvider
    if (!username) return;
    const fetchBusinessDetails = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings/getBusinessDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
          }),
        }
      );

      const data = await response.json();
      console.log("this is data", data);
      setBusinessName(data.message.businessName);
      console.log("data.message.settings?.logo", data.message.settings?.logo);
      setLogo(data.message.settings?.logo);
    };

    fetchBusinessDetails();
  }, [username]);

  // console.log("this is window", window.location.pathname);
  useEffect(() => {
    console.log("in useEffect 20");
    setSelected(2);

    if (window.location.pathname.startsWith("/app/project")) {
      setSelected(0);
    } else if (window.location.pathname.startsWith("/app/mrPancake")) {
      //project not projects
      setSelected(1);
    } else if (window.location.pathname.startsWith("/app/contact")) {
      setSelected(2);
    } else if (window.location.pathname.startsWith("/app/forms")) {
      setSelected(3);
    } else if (window.location.pathname.startsWith("/app/workflows")) {
      setSelected(4);
    } else if (window.location.pathname.startsWith("/app/gallery")) {
      setSelected(5);
    } else if (window.location.pathname.startsWith("/app/settings")) {
      setSelected(6);
    } else {
      setSelected(0);
    }
  }, []);

  const navigate = useNavigate();
  return (
    <div
      className="w-[350px] flex flex-col gap-3
     border-r-[10px] border-beach
      h-screen px-7 pt-7 sticky top-0 left-0
      bg-[#F5F5F5]
      "
    >
      <div className="flex items-center gap-2 py-10 border-b-2 mb-5">
        {logo && <img src={logo} alt="logo" className="w-12 rounded-full" />}
        <p className="font-medium">{businessName}</p>
      </div>
      <NavItem
        navIndex={0}
        selected={selected}
        setSelected={setSelected}
        navItemText="Home"
        Icon={HomeIcon}
        subMenuElements={null}
        navItemUrl={"/app/home"}
      />
      <NavItem
        navIndex={1}
        selected={selected}
        setSelected={setSelected}
        navItemText="Mr. Pancake"
        Icon={PancakeIcon}
        navItemUrl={"/app/mrPancake"}
      />
      <NavItem
        navIndex={2}
        selected={selected}
        setSelected={setSelected}
        navItemText="Contacts"
        Icon={ProjectsIcon}
        navItemUrl={"/app/contacts"}
      />
      <NavItem
        navIndex={3}
        selected={selected}
        setSelected={setSelected}
        navItemText="Forms"
        Icon={FormIcon}
        navItemUrl={"/app/forms"}
      />
      <NavItem
        navIndex={4}
        selected={selected}
        setSelected={setSelected}
        navItemText="Workflows"
        Icon={WorkflowIcon}
        navItemUrl={"/app/workflows"}
      />

      <NavItem
        navIndex={5}
        selected={selected}
        setSelected={setSelected}
        navItemText="Photo Gallery"
        Icon={ImageIcon}
        navItemUrl={"/app/gallery"}
      />

      <NavItem
        navIndex={6}
        selected={selected}
        setSelected={setSelected}
        navItemText="Settings"
        Icon={SettingsIcon}
        navItemUrl={"/app/settings"}
      />

      <div className="mt-auto mb-10">
        <div
          className="flex items-center gap-5 cursor-pointer"
          onClick={() => {
            localStorage.removeItem("user");
            navigate("/");
          }}
        >
          <LogoutIcon width={30} height={30} className="cursor-pointer" />
          <p className="">Log out</p>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;
