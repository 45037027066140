import React from "react";

const MobileNavMenu = ({ showMobileNavMenu, setShowMobileNavMenu }) => {
  return (
    <div className="fixed h-full bg-[#201F36] w-full text-white pt-7 px-5 z-10">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <img
            src={require("../../assets/images/pancake-logo.png")}
            alt="PancakeCRM Logo"
            className="w-8 text-primary"
          />
          <p
            className="font-bold text-2xl cursor-pointer"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            PancakeCRM
          </p>
        </div>
        <img
          src={require("../../assets/icons/iphone-close.png")}
          alt="PancakeCRM Logo"
          className="w-8 cursor-pointer"
          onClick={() => setShowMobileNavMenu(false)}
        />
      </div>
      <div className="mt-10 flex flex-col">
        <a
          href="/"
          className="px-2 py-4 border-t border-b border-gray-500 text-base"
        >
          Home
        </a>
        {/* <p className="px-2 py-4 border-b-2">Blog</p> */}
        <a
          href="/pricing"
          className="px-2 py-4 border-b border-gray-500 text-base"
        >
          Pricing
        </a>
        <a
          href="/blog"
          className="px-2 py-4 border-b border-gray-500 text-base"
        >
          Blog
        </a>
        {/* <p className="px-2 py-4 border-b-2">About</p> */}
      </div>
    </div>
  );
};

export default MobileNavMenu;
