import React, { useState, useEffect, useContext } from "react";
import AddFolderPopup from "./AddFolderPopup";

import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import RemoveDeliveryPopup from "./RemoveDeliveryPopup";
import Topnav from "../Utils/Topnav";

const GalleryMenu = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [logoSet, setLogoSet] = useState(false);
  const [loading, setLoading] = useState(true);
  const [removePopupIndex, setRemovePopupIndex] = useState(-1);
  const [folders, setFolders] = useState([]);

  const navigate = useNavigate();

  console.log("trace 20");
  const username = JSON.parse(localStorage.getItem("user")).username;
  console.log("this is username too", username);

  useEffect(() => {
    const getFolders = async () => {
      if (!username) return;
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/delivery/getFolders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username }),
        }
      );
      const data = await res.json();
      console.log("data2", data);
      setFolders(data.message.folders);
      setLogoSet(data.message.logoSet);
      setLoading(false);
    };

    getFolders();
  }, [refresh, username]);

  const addFolder = () => {
    setShowPopup(true);
  };
  // console.log("folders", folders);

  console.log("this is loading", loading);
  if (loading) {
    return <div className="w-full">Loading...</div>;
  }

  if (!logoSet) {
    return (
      <div className="flex flex-wrap cursor-pointer gap-20 ml-10 mt-10 w-full">
        <p>
          Please set a logo in{" "}
          <a href="/app/settings" className="text-blue-500 underline">
            settings
          </a>{" "}
          to use the Delivery Feature
        </p>
      </div>
    );
  }
  return (
    <div className="w-full relative">
      {showPopup && (
        <AddFolderPopup
          title="Add a Folder"
          heading="Type a folder name"
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setRefresh={setRefresh}
        />
      )}
      {removePopupIndex > -1 && (
        <RemoveDeliveryPopup
          removePopupIndex={removePopupIndex}
          setRemovePopupIndex={setRemovePopupIndex}
          delivery={folders[removePopupIndex]}
          setRefresh={setRefresh}
        />
      )}

      <div className="flex flex-col gap-10">
        <Topnav
          pageTitle="Photo Gallery"
          showBackButton={false}
          primaryButtonText="+ New Gallery"
          primaryButtonOnClick={addFolder}
        />
        <div className="flex flex-wrap cursor-pointer gap-2">
          {folders &&
            folders.map((folder, index) => (
              <div
                className="flex justify-between items-center
              border border-gray-200 rounded-lg p-4 w-full cursor-pointer
               hover:bg-gray-100 group h-[70px] 
        "
                onClick={() => navigate(`/app/gallery/${folder.id}`)}
              >
                <div className="flex items-center gap-4">
                  {folder.coverPhoto ? (
                    <img
                      src={folder?.coverPhoto}
                      alt=""
                      className="w-12 h-12 rounded-lg"
                    />
                  ) : (
                    <div className="w-12 h-12 bg-gray-200 rounded-lg"></div>
                  )}
                  <p>{folder.settings.folderName}</p>
                </div>

                <div className="flex gap-8">
                  <TrashIcon
                    className="-ml-4 hidden group-hover:block cursor-pointer"
                    stroke="black"
                    fill="#ffffff"
                    width="30"
                    height="30"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemovePopupIndex(index);
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryMenu;
