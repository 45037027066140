import { ReactComponent as PlusCircledIcon } from "../../../assets/icons/plus-circled.svg";
import { ReactComponent as CurvedArrowIcon } from "../../../assets/icons/App/WorkflowBuilder/curved-arrow.svg";
import { ReactComponent as StraightArrowIcon } from "../../../assets/icons/App/WorkflowBuilder/straight-arrow.svg";

import React, { useState } from "react";
import AddOptionsPopup from "./AddOptionsPopup";
import EditEventPopup from "./EditEventPopup/EditEventPopup";

const DisplayNode = ({
  nodeId,
  node,
  arrows,
  popupIndex,
  setPopupIndex,
  setTree,
  tree,
  popupDirection,
  setPopupDirection,
  setEditEventPopupDetails,
}) => {
  var arrowDivs = [];
  if (arrows.includes("left") && arrows.includes("right")) {
    arrowDivs = [
      <div className="relative w-full">
        <CurvedArrowIcon
          fill="gray"
          className="mx-auto p-1 w-full  -scale-x-100 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
        />
        <PlusCircledIcon
          className="mb-10 mx-auto cursor-pointer absolute top-[55%] left-[57%] -translate-y-1/2 -translate-x-1/2
          hover:fill-brown hover:stroke-white"
          width="30"
          height="30"
          stroke="rgba(0,0,0,0.5)"
          fill="white"
          onClick={() => {
            setPopupIndex(nodeId);
            setPopupDirection("left");
          }}
        />
      </div>,
      <div className="relative w-full">
        <CurvedArrowIcon fill="gray" className="mx-auto p-1 w-full" />
        <PlusCircledIcon
          className="mb-10 mx-auto cursor-pointer absolute top-[55%] left-[43%] -translate-y-1/2 -translate-x-1/2
          hover:fill-brown hover:stroke-white"
          width="30"
          height="30"
          stroke="rgba(0,0,0,0.5)"
          fill="white"
          onClick={() => {
            setPopupIndex(nodeId);
            setPopupDirection("right");
          }}
        />
      </div>,
    ];
  } else if (arrows.includes("left")) {
    arrowDivs = [
      <div className="relative -z-10">
        <CurvedArrowIcon
          fill="gray"
          className="mx-auto p-1 w-full -scale-x-100 absolute"
        />
        <PlusCircledIcon
          className="mb-10 mx-auto cursor-pointer absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 
          hover:fill-brown hover:stroke-white"
          width="30"
          height="30"
          stroke="rgba(0,0,0,0.5)"
          fill="white"
          onClick={() => {
            setPopupIndex(nodeId);
            setPopupDirection("left");
          }}
        />
      </div>,
      <div className="w-full"></div>,
    ];
  } else if (arrows.includes("right")) {
    arrowDivs = [
      <div className="w-full"></div>,

      <CurvedArrowIcon fill="gray" className="mx-auto p-1 w-full" />,
    ];
  } else {
    arrowDivs = [
      <div className="relative">
        <StraightArrowIcon fill="gray" className="mx-auto w-full  p-1 " />
        <PlusCircledIcon
          className="mb-10 mx-auto cursor-pointer absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2
           hover:fill-brown hover:stroke-white"
          width="30"
          height="30"
          stroke="rgba(0,0,0,0.5)"
          fill="white"
          onClick={() => {
            setPopupIndex(nodeId);
            setPopupDirection("straight");
          }}
        />
      </div>,
    ];
  }

  function generateNodeText(node) {
    if (node.details.type === "form_submission") {
      return [`If form ${node.details.formName} filled out`, ""];
    } else if (node.details.type === "delay") {
      return [`Delay by`, `${node.details.duration} ${node.details.units}`];
    } else if (node.details.type === "send_email") {
      console.log("trace21", node.details);
      return ["Send an email", node.details.subject];
    } else if (node.details.type === "move_to_stage") {
      return ["Move contact to", node.details.stage];
    } else if (node.details.type === "if_else") {
      return [`If ${node.details.condition} is filled out`, ""];
    }
  }

  return (
    <div className="">
      <p>
        {/* selectedEventType:{selectedEventType?.toString()} showEditEventPopup: */}
        {/* {showEditEventPopup?.toString()} */}
      </p>
      <div
        className={`flex flex-col  justify-center
                  w-[400px] h-[80px] mx-auto
                  px-20 py-2
                  bg-brown border-primary rounded-lg
                 cursor-pointer hover:bg-beach`}
        style={{ borderLeftColor: node.details.color }}
        onClick={() => {
          setEditEventPopupDetails({ nodeId });
        }}
      >
        <p className="text-sm font-medium text-center">
          {generateNodeText(node)[0]}
        </p>
        <p className="text-sm text-gray-500 text-center">
          {generateNodeText(node)[1]}
        </p>
      </div>
      <div className="flex w-full justify-center">{arrowDivs} </div>
    </div>
  );
};

export default DisplayNode;
