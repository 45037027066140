import React, { useState, useEffect } from "react";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import DelayPopup from "./DelayPopup";
import IfElsePopup from "./IfElsePopup";
import SendEmailPopup from "./SendEmailPopup";
import MoveToStagePopup from "./MoveToStagePopup";
import FormFilledPopup from "./FormFilledPopup";

const EditEventPopup = ({
  setTree,
  popupIndex,
  setPopupIndex,
  nodeId,
  setRootId,
  popupDirection,
  editEventPopupDetails,
  setEditEventPopupDetails,
  edit,
  tree,
}) => {
  // editEventPopupDetails is {selectedEventType} when adding a new node
  // editEventPopupDetails is {nodeId} when editing an existing node

  const [details, setDetails] = useState({});

  useEffect(() => {
    if (!editEventPopupDetails.nodeId) return;
    console.log("trace 150", tree[editEventPopupDetails.nodeId].details);
    setDetails(tree[editEventPopupDetails.nodeId].details);
    // setEditEventPopupDetails({ selectedEventType: node.details.type });
  }, [
    edit,
    setEditEventPopupDetails,
    tree,
    nodeId,
    editEventPopupDetails.nodeId,
    setDetails,
  ]);

  console.log("here are the details", details);

  // add child to node. node being passed in is popupIndex
  function addChildToNode(nodeId, popupDirection) {
    if (nodeId === -1) {
      const thisRootId = Date.now();
      setRootId(thisRootId);
      // root node
      setTree({
        [thisRootId]: {
          details: details,
          children: [],
        },
      });
    } else {
      const childNodeId = Date.now();

      // if we have an if/else branch, insert two children
      // if (popupDirection is on the left or straight) then insert the child between parent and 1st child
      // else if (popupDirection is on the right) then insert the child between parent and 2nd child
      setTree((prevTree) => {
        console.log("this is prevTree", prevTree);
        const newTree = JSON.parse(JSON.stringify(prevTree));
        var currentChildIdx;
        var children;

        if (popupDirection === "straight" || popupDirection === "left") {
          currentChildIdx = 0;
        } else {
          currentChildIdx = 1;
        }

        const currentChild = newTree[nodeId].children[currentChildIdx];
        newTree[nodeId].children[currentChildIdx] = childNodeId;

        if (details.type === "if_else") {
          children = [currentChild, undefined];
        } else {
          children = [currentChild];
        }

        newTree[childNodeId] = {
          details: details,
          children: children,
        };

        return newTree;
      });
    }
    // regardless of if/else branch
    setPopupIndex(null);
    setEditEventPopupDetails({});
  }

  var heading;
  var subHeading;
  var content;

  const eventType = details.type || editEventPopupDetails.selectedEventType;
  const isEditing = Boolean(editEventPopupDetails.nodeId);
  if (eventType === "delay") {
    heading = "Delay settings";
    subHeading =
      "Choose how long a contact will be delayed before moving forward";
    content = (
      <DelayPopup
        details={details}
        setDetails={setDetails}
        isEditing={isEditing}
      />
    );
  } else if (eventType === "send_email") {
    heading = "Send email settings";
    subHeading = "";
    console.log("this is details", details);
    content = (
      <SendEmailPopup
        details={details}
        setDetails={setDetails}
        isEditing={isEditing}
      />
    );
  } else if (eventType === "if_else") {
    heading = "If/else settings";
    subHeading =
      "Your contact will join the 'Yes' path if they meet your conditions.";
    content = (
      <IfElsePopup
        details={details}
        setDetails={setDetails}
        isEditing={isEditing}
      />
    );
  } else if (eventType === "move_to_stage") {
    heading = "Move to settings";
    subHeading = "Move your contact to this stage";
    content = (
      <MoveToStagePopup
        details={details}
        setDetails={setDetails}
        isEditing={isEditing}
      />
    );
  } else if (eventType === "form_submission") {
    heading = "Form filed out settings";
    subHeading = "If your contact fills out this form, they will move forward";
    content = (
      <FormFilledPopup
        details={details}
        setDetails={setDetails}
        isEditing={isEditing}
      />
    );
  }

  return (
    <div
      className="flex flex-col gap-10 justify-between px-1
   bg-gray-100
    absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
    z-40
    "
    >
      <div className="flex items-center justify-between py-4 border-b-2">
        <CrossIcon
          stroke="black"
          width={35}
          height={35}
          className="float-right cursor-pointer invisible"
          onClick={() => {
            // setPopupIndex(null);
            // TODO: might have to add this back in
            // setPopupDirection(null);
          }}
        />
        <p className="font-bold">{heading}</p>
        <CrossIcon
          stroke="black"
          width={35}
          height={35}
          className="float-right cursor-pointer"
          onClick={() => {
            setPopupIndex(null);
            setEditEventPopupDetails({});
          }}
        />
      </div>
      <div className="flex flex-col gap-4 px-10">
        <p>{subHeading}</p>
        {content}
      </div>
      <div className="flex items-center justify-end py-4 border-t-2">
        <button
          className="mr-4 bg-brown hover:bg-beach px-2 py-1 rounded-lg"
          onClick={() => {
            // if editing, save
            if (editEventPopupDetails.nodeId) {
              setTree((prevTree) => {
                const newTree = JSON.parse(JSON.stringify(prevTree));
                newTree[editEventPopupDetails.nodeId].details = details;
                return newTree;
              });
              setPopupIndex(null);
              setEditEventPopupDetails({});
              // otherwise, add child to node
            } else {
              addChildToNode(popupIndex, popupDirection);
            }
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditEventPopup;
