import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router-dom";

import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
// import Slide3 from "./Slide3";

const Onboarding = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const username = JSON.parse(localStorage.getItem("user")).username;

  const goToNextSlide = async () => {
    // const result = await fetch(
    //   `${process.env.REACT_APP_BACKEND_URL}/home/setOnboardingStatus`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ username, slideIndex, completed: false }),
    //   }
    // );
    // if (result.ok) {
    setSlideIndex((prevSlideIndex) => prevSlideIndex + 1);
    // }
  };

  // const finishOnboarding = async () => {
  //   const result = await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}/home/changeOnboardingStatus`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ username, completed: true }),
  //     }
  //   );
  //   if (result.ok) {
  //     console.log("onboarding finished");
  //   }
  // };

  let Slide;

  switch (slideIndex) {
    case 0:
      Slide = <Slide1 goToNextSlide={goToNextSlide} />;
      break;
    case 1:
      Slide = <Slide2 goToNextSlide={goToNextSlide} />;
      break;
    case 2:
      Slide = <Slide3 goToNextSlide={goToNextSlide} />;
      break;
    default:
      Slide = <Slide1 goToNextSlide={goToNextSlide} />;
  }

  return (
    <div className="fixed top-0 left-0 bg-white w-screen h-screen">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        {Slide}
      </div>
    </div>
  );
};

export default Onboarding;
