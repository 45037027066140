import React, { useState, useEffect, useContext } from "react";
import LogoUploadPopup from "./LogoUploadPopup";
import { GoogleButton } from "react-google-button";

const Settings = () => {
  const [logo, setLogo] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isEmailIntegrated, setIsEmailIntegrated] = useState(false);
  const [email, setEmail] = useState("");
  const [showDisclosure, setShowDisclosure] = useState(false);
  const username = JSON.parse(localStorage.getItem("user")).username;
  useEffect(() => {
    if (!username) return;
    const fetchBusinessDetails = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings/getBusinessDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
          }),
        }
      );

      const data = await response.json();
      console.log("this is data 33", data);
      setBusinessName(data.message.businessName);
      setLogo(data?.message?.settings?.logo);
      // console.log("logging this", data.message.isEmailIntegrated);
      setIsEmailIntegrated(data.message.isEmailIntegrated);
      setEmail(data.message.email);
    };

    fetchBusinessDetails();
  }, [refresh, username]);
  const handleClick = async () => {
    console.log(
      "this is tr82",
      `${process.env.REACT_APP_BACKEND_URL}/google/redirectToOAuth2`
    );
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/google/redirectToOAuth2`;
  };
  const [businessName, setBusinessName] = useState("");
  const [showLogoUploadPopup, setShowLogoUploadPopup] = useState(false);

  const handleSubmitBusinessDetails = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings/setBusinessDetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          businessName,
        }),
      }
    );

    const data = await response.json();
    console.log("this is data", data);
  };

  const saveSettings = async () => {
    await handleSubmitBusinessDetails();
  };

  const removeEmailIntegration = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings/removeEmailIntegration`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
        }),
      }
    );

    const data = await response.json();

    if (data.ok) {
      setIsEmailIntegrated(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 mx-auto mt-10 w-full">
      <div className="flex flex-col gap-10">
        <div className="flex justify-between items-center py-2 mb-8">
          <p className="text-xl font-medium">Settings</p>
          <button
            onClick={saveSettings}
            className=" bg-brown hover:bg-beach text-black px-3 py-2 rounded-lg text-sm font-medium"
          >
            Save
          </button>
        </div>
        <div className="flex gap-2">
          <p className="w-[300px] font-medium">Logo</p>
          <div className="relative">
            <img
              src={logo}
              className="h-40 rounded-lg bg-gray-100"
              alt="logo"
            />

            <button
              className="text-base absolute bottom-0 -translate-y-1 text-white bg-gray-200/50 w-full text-center"
              onClick={() => setShowLogoUploadPopup(true)}
            >
              Change
            </button>
          </div>
        </div>
        <div className="flex gap-2">
          <p className="w-[300px] font-medium">Business Name</p>
          <input
            type="text"
            onChange={(e) => setBusinessName(e.target.value)}
            value={businessName}
            className="py-1 focus:ring-0 border-2 border-transparent"
            style={{ width: "300px" }}
          />
        </div>
        <div className="flex gap-2">
          <div className="w-[300px]">
            <p className="w-[300px] font-medium">Email Integration</p>
            <button
              className="text-xs text-blue-500 underline"
              onClick={() =>
                setShowDisclosure((prevShowDisclosure) => !prevShowDisclosure)
              }
            >
              Disclosure
            </button>
            {showDisclosure && (
              <p className="text-xs">
                PancakeCRM's use and transfer of information received from
                Google APIs to any other app will adhere to{" "}
                <span
                  className="text-blue-500 underline cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://developers.google.com/terms/api-services-user-data-policy",
                      "_blank"
                    )
                  }
                >
                  Google API Services
                </span>{" "}
                User Data Policy, including the Limited Use requirements.
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm">{email}</p>

            {isEmailIntegrated ? (
              <div className="flex gap-2">
                <button className="w-[125px] text-sm bg-brown opacity-75 disabled text-black font-medium py-2 px-4 rounded cursor-default ">
                  Connected
                </button>
                <button
                  className="text-blue-500 underline text-sm"
                  onClick={removeEmailIntegration}
                >
                  Disconnect
                </button>
              </div>
            ) : (
              <GoogleButton
                type="light" // can be light or dark
                label="Connect with Google"
                onClick={handleClick}
              />
            )}
          </div>
        </div>
      </div>

      {showLogoUploadPopup && (
        <LogoUploadPopup
          setShowPopup={setShowLogoUploadPopup}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
};

export default Settings;
