import React, { useEffect, useState } from "react";

import PhotoAlbum from "react-photo-album";
import LightboxPopup from "./LightboxPopup";

import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { useGetDevice } from "../../../App/Utils/useGetDevice";

export default function PhotoGrid({ delivery }) {
  const [sceneIndex, setSceneIndex] = useState(-1);
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const promises = delivery?.contents.map((scene) =>
      Promise.all(getPhotosFromScene(scene))
    );
    Promise.all(promises).then((scenes) => {
      console.log("this is scenes", scenes);
      setPhotos(scenes);
    });
  }, [delivery]);

  function getPhotosFromScene(scene) {
    return scene.photos.map((photo, index) => {
      const img = new Image();
      img.src = photo.url;
      return new Promise((resolve, reject) => {
        img.onload = () => {
          resolve({
            src: photo.url,
            width: img.naturalWidth,
            height: img.naturalHeight,
          });
        };
        img.onerror = reject;
      });
    });
  }

  const device = useGetDevice();

  const layout = "masonry";
  const columns = device === "mobile" ? 1 : 4;

  if (photos.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div class="flex justify-center items-center">
          <div class="animate-spin rounded-full h-16 w-16 border-b-[8px] border-gray-900"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-36 lg:mx-16 my-40">
      {sceneIndex > -1 && photoIndex > -1 && (
        <LightboxPopup
          sceneIndex={sceneIndex}
          setSceneIndex={setSceneIndex}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
          delivery={delivery}
          device={device}
        />
      )}
      {/* negative margin and padding added so when you anchor here, the elements
      don't show up from the top of the screen */}
      {photos.map((scenePhotos, sceneIndex) => (
        <div
          className="flex flex-col gap-8 lg:gap-24 -mt-[100px] pt-[100px] lg:-mt-[200px] lg:pt-[200px]"
          id={sceneIndex.toString()}
        >
          <p className="text-center text-xl lg:text-4xl font-medium text-gray-500">
            {delivery.contents[sceneIndex].name}
          </p>
          <PhotoAlbum
            layout={layout}
            spacing={5}
            columns={columns}
            renderDefaultPhoto
            photos={scenePhotos}
            targetRowHeight={150}
            onClick={({ index }) => {
              setSceneIndex(sceneIndex);
              setPhotoIndex(index);
            }}
            renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
              <div
                style={{ position: "relative", ...wrapperStyle }}
                className="group"
              >
                {renderDefaultPhoto({ wrapped: true })}
                {/* {photo.title && ( */}
                <div
                  className="hidden group-hover:block float-left"
                  style={{
                    position: "absolute",
                    overflow: "hidden",
                    inset: "auto 0 0 0",
                    padding: "7px 8px",
                    background:
                      "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5))",
                  }}
                >
                  <a
                    className="text-gray-200"
                    download={photo.src}
                    href={photo.src}
                  >
                    <DownloadIcon
                      stroke="white"
                      width={35}
                      height={30}
                      className="hidden lg:block cursor-pointer"
                      // onClick={() => setShowPopup(false)}
                    />
                  </a>
                </div>
              </div>
            )}
          />
        </div>
      ))}
    </div>
  );
}
