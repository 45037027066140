import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PhotosUploader from "./PhotosUploader";

import ShareGalleryPopup from "./ShareGalleryPopup";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import GallerySettings from "./GallerySettings";

import ProgressBar from "./ProgressBar";
import Topnav from "../Utils/Topnav";

const GalleryEditor = () => {
  const { id } = useParams();

  const [refresh, setRefresh] = useState(true);

  const [delivery, setDelivery] = useState({ contents: [] });

  const [showPopup, setShowPopup] = useState(false);

  const [selectedSceneIndex, setSelectedSceneIndex] = useState();

  // tracks if a new scene was added
  const [scenesSaved, setScenesSaved] = useState(true);

  const [waitingForUpload, setWaitingForUpload] = useState(false);

  const [photosChanged, setPhotosChanged] = useState(false);

  // tracks if the delivery was edited using the edit button
  const [isDeliveryEdited, setIsDeliveryEdited] = useState(false);

  const username = JSON.parse(localStorage.getItem("user")).username;
  // settings

  useEffect(() => {
    console.log("in useEffect 121");
    const getGallery = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/delivery/getFolder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, id: id }),
        }
      );
      const data = await res.json();
      console.log("data2", data);
      setDelivery(data.message.delivery);
    };
    getGallery();
    setWaitingForUpload(false);
  }, [refresh]);

  const uploadPhotos = () => {
    console.log("upload photos");
  };

  const saveScenes = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/delivery/saveScenes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          folderId: id,
          delivery: delivery,
          photosChanged: photosChanged,
        }),
      }
    );

    const result = await response.json();

    if (result.ok) {
      setScenesSaved(true);
      setIsDeliveryEdited(false);
    }
  };

  const navigate = useNavigate();

  const removeScene = (sceneIndex) => {
    setDelivery((prevDelivery) => {
      const newDelivery = { ...prevDelivery };
      newDelivery.contents.splice(sceneIndex, 1);
      return newDelivery;
    });
  };

  const removePhoto = (sceneIndex, photoIndex) => {
    setDelivery((prevDelivery) => {
      const newDelivery = { ...prevDelivery };
      // remove the photo from the scene
      newDelivery.contents[sceneIndex].photos.splice(photoIndex, 1);
      return newDelivery;
    });
  };

  const moveSceneUp = (sceneIndex) => {
    setDelivery((prevDelivery) => {
      const newDelivery = { ...prevDelivery };
      // remove the photo from the scene
      const scene = newDelivery.contents[sceneIndex];
      newDelivery.contents.splice(sceneIndex, 1);
      newDelivery.contents.splice(sceneIndex - 1, 0, scene);
      return newDelivery;
    });
  };

  const moveSceneDown = (sceneIndex) => {
    setDelivery((prevDelivery) => {
      const newDelivery = { ...prevDelivery };
      // remove the photo from the scene
      const scene = newDelivery.contents[sceneIndex];
      newDelivery.contents.splice(sceneIndex, 1);
      newDelivery.contents.splice(sceneIndex + 1, 0, scene);
      return newDelivery;
    });
  };

  return (
    <div className="">
      {/* <p>{photosChanged.toString()}</p> */}
      {selectedSceneIndex !== undefined && (
        <PhotosUploader
          selectedSceneIndex={selectedSceneIndex}
          setSelectedSceneIndex={setSelectedSceneIndex}
          folderId={id}
          setRefresh={setRefresh}
          waitingForUpload={waitingForUpload}
          setWaitingForUpload={setWaitingForUpload}
        />
      )}

      <Topnav
        pageTitle={delivery?.settings?.folderName}
        showBackButton={true}
        backButtonUrl="/app/gallery"
        primaryButtonText={(!scenesSaved || isDeliveryEdited) && "Save"}
        primaryButtonOnClick={saveScenes}
        secondaryButtonText="Edit"
        secondaryButtonOnClick={() => setIsDeliveryEdited(true)}
      />

      {showPopup && <ShareGalleryPopup setShowPopup={setShowPopup} />}

      <div className="mt-10 flex flex-col gap-10">
        {waitingForUpload && (
          <div>
            <p className="mb-2">
              Uploading... You can come back later to see your photos
            </p>
            <ProgressBar waitingForUpload={waitingForUpload} />
          </div>
        )}
        {delivery.contents.map((scene, sceneIndex) => (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                {isDeliveryEdited && (
                  <>
                    <TrashIcon
                      className="bg-gray-100 rounded-lg cursor-pointer"
                      stroke="black"
                      fill="#ffffff"
                      width="30"
                      height="30"
                      onClick={() => {
                        removeScene(sceneIndex);
                        setPhotosChanged(true);
                        // setRemovePopupIndex(index);
                      }}
                    />

                    <div className="flex flex-col gap-0">
                      {sceneIndex !== 0 && (
                        <ChevronIcon
                          name="up arrow"
                          className="bg-gray-100 rounded-lg cursor-pointer -rotate-90"
                          stroke="black"
                          fill="black"
                          width="20"
                          height="20"
                          onClick={() => {
                            moveSceneUp(sceneIndex);
                            setPhotosChanged(true);
                          }}
                        />
                      )}
                      {sceneIndex !== delivery.contents.length - 1 && (
                        <ChevronIcon
                          name="down arrow"
                          className="bg-gray-100 rounded-lg cursor-pointer rotate-90"
                          stroke="black"
                          fill="black"
                          width="20"
                          height="20"
                          onClick={() => {
                            moveSceneDown(sceneIndex);
                            setPhotosChanged(true);
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
                <input
                  className="text-2xl font-semibold placeholder:italic w-fit"
                  placeholder="Enter scene name"
                  onChange={(e) => {
                    setDelivery((prevDelivery) => {
                      console.log("this is prevDelivery", prevDelivery);
                      const newDelivery = { ...prevDelivery };
                      newDelivery.contents[sceneIndex].name = e.target.value;
                      return newDelivery;
                    });
                    setScenesSaved(false);
                  }}
                  value={scene.name}
                />
              </div>
            </div>

            <div className="flex flex-col gap-6 overflow-x-scroll">
              <div className="flex gap-2 items-center pt-4">
                <button
                  className={`flex justify-center items-center min-w-[200px] h-[120px]
                 bg-gray-200 border-2 rounded-lg ${
                   !scenesSaved && "cursor-not-allowed"
                 }`}
                  disabled={!scenesSaved}
                  onClick={() => setSelectedSceneIndex(sceneIndex)}
                >
                  {/* <div className="w-[50px] h-[50px] bg-black"></div> */}
                  <div className="text-5xl text-gray-400 -mt-1 ">+</div>
                </button>

                {scene.photos.map((photo, photoIndex) => (
                  <div className="relative">
                    {/* <p>ok2</p> */}
                    {isDeliveryEdited && (
                      <TrashIcon
                        className="bg-gray-100 rounded-lg cursor-pointer absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 mr-1 mt-1"
                        stroke="black"
                        fill="#ffffff"
                        width="30"
                        height="30"
                        onClick={() => {
                          removePhoto(sceneIndex, photoIndex);
                          // setRemovePopupIndex(index);
                          setPhotosChanged(true);
                        }}
                      />
                    )}
                    <img
                      src={photo.url}
                      alt="gallery"
                      className="min-w-[200px] max-w-[200px] rounded-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        <button
          className="bg-brown text-sm hover:bg-beach font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
          onClick={(e) => {
            // e.preventDefault();
            setDelivery((prevDelivery) => {
              var newDelivery = { ...prevDelivery };
              console.log("this is prevDelivery", prevDelivery);
              newDelivery.contents.push({ name: "", photos: [] });
              console.log("this is newDelivery", newDelivery);
              return newDelivery;
            });
            setScenesSaved(false);
          }}
        >
          Add Scene
        </button>
        <GallerySettings
          folderId={id}
          delivery={delivery}
          setDelivery={setDelivery}
          setRefresh={setRefresh}
          setScenesSaved={setScenesSaved}
          setPhotosChanged={setPhotosChanged}
        />
      </div>
    </div>
  );
};

export default GalleryEditor;
