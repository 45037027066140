import React, { useEffect, useState, useRef } from "react";
import WorkflowBuilder from "../WorkflowBuilder/WorkflowBuilder";

const RenderWorkflow = ({ message, index }) => {
  const [savedStatus, setSavedStatus] = useState("not_saved");
  const [rootId, setRootId] = useState({});
  const [workflow, setWorkflow] = useState({});
  const [title, setTitle] = useState("This workflow");
  const [error, setError] = useState(false);

  console.log("this is message.content", message.content);
  const username = JSON.parse(localStorage.getItem("user")).username;

  // take the s in as input and return the workflow
  const parseWorkflow = (s) => {
    let startPos = s.search("<workflowJSON>") + "<workflowJSON>".length;
    let endPos = s.search("</workflowJSON>");
    let slicedString = s.slice(startPos, endPos);
    console.log("this is slicedString", slicedString);
    return JSON.parse(slicedString);
  };

  useEffect(() => {
    console.log("in useEffect");
    try {
      const thisWorkflow = parseWorkflow(message.content);
      console.log("here is the workflow", thisWorkflow);
      setWorkflow(thisWorkflow);
    } catch (e) {
      console.log("error in parsing workflow", e);
      setError(e);
    }
  }, []);

  const saveWorkflow = async () => {
    setSavedStatus("saving");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/workflow/addWorkflow`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fromMrPancake: true,
          username: username,
          title: title,
          rootId: rootId,
          workflow: workflow,
        }),
      }
    );
    const data = await response.json();
    console.log("this is data", data);
    if (data.ok) {
      setSavedStatus("saved");
    }
  };

  if (error) {
    return (
      <p className="text-base w-full whitespace-pre-line">
        Please try again with a different prompt and we should be able to
        generate your workflow!
      </p>
    );
  }
  try {
    return <WorkflowBuilder workflowObject={workflow} />;
    // return <div>{message.content}</div>;
  } catch (err) {
    return (
      <p className="text-sm w-full whitespace-pre-line">
        Please try again with a different prompt
      </p>
    );
  }

  // console.log("this is parseEmail", parseEmail(message));
  // console.log("this is parseEmail 2", JSON.parse(parseEmail(message)));
};

export default RenderWorkflow;
