import { useState, useEffect } from "react";

export function useGetDevice() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let device;

  if (width <= 768) {
    device = "mobile";
  } else if (width <= 1024) {
    device = "tablet";
  } else {
    device = "desktop";
  }

  return device;
}
