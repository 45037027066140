import React from "react";

import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron.svg";
import { useNavigate } from "react-router-dom";
const NavItem = ({
  navIndex,
  selected,
  setSelected,
  navItemText,
  navItemUrl,
  Icon,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4" onClick={() => navigate(navItemUrl)}>
      <div
        className={`flex items-center gap-4 hover:bg-brown px-2 py-3 cursor-pointer rounded-lg ${
          selected === navIndex && "bg-brown"
        }`}
        onClick={() => setSelected(navIndex)}
      >
        <Icon
          fill="#121127"
          width="20px"
          height="20px"
          // className="group-hover:fill-brown"
        />
        <p className="text-base font-medium">{navItemText}</p>
      </div>
    </div>
  );
};

export default NavItem;
