import React from "react";
import "./Hero.css";
import moment from "moment";
const Hero = ({ delivery, user }) => {
  console.log("this is delivery", delivery);

  const momentDate = moment.utc(delivery?.settings?.date);
  const formattedDate = momentDate.format("MMMM D, YYYY");

  const isMobile = window.innerWidth < 768;

  return (
    <div>
      {/* <div className="w-screen h-screen relative overflow-visible resize">
        <img src={delivery?.coverPhoto} alt="cover" className="h-full w-auto" />
        <div className="bg-black/20 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-screen h-full"></div>
        <div className="absolute bottom-20 left-28 flex flex-col gap-8">
          <h1 className="text-6xl text-white">
            {delivery?.settings?.folderName}
          </h1>
          <h1 className="text-3xl text-white ">{formattedDate}</h1>
        </div>
      </div> */}
      {/* <div className="h-screen bg-blue-200 mx-auto absolute top-0 left-0 right-0 bottom-0"> */}

      <div className="h-screen flex flex-col justify-center items-center">
        <div className="h-screen w-full overflow-hidden relative">
          <img
            className="h-full w-full object-cover"
            src={delivery.coverPhoto}
            alt="placeholder"
          />
          <div className="bg-black/20 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-screen h-full"></div>
          <div className="hidden lg:flex absolute p-4 bottom-20 left-28 flex-col gap-8 ">
            <h1 className="text-6xl text-white">
              {delivery?.settings?.folderName}
            </h1>
            <h1 className="text-3xl text-white ">{formattedDate}</h1>
          </div>
        </div>
        <div className="flex lg:hidden flex-col items-center gap-4 my-20">
          <h1 className="text-3xl text-gray-500">
            {delivery?.settings?.folderName}
          </h1>
          <h1 className="text-xl text-gray-500 ">{formattedDate}</h1>
          <img src={user?.settings?.logo} alt="logo" className="mt-2 h-16" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
