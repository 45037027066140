import React, { useState, useEffect, useContext } from "react";

import Onboarding from "../Onboarding/Onboarding";
import OptionsPopup from "./OptionsPopup";
import AddProjectPopup from "./AddContactPopup";
import RemoveProjectPopup from "./RemoveContactPopup";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import InitialsPicture from "../Utils/InitialsPicture";
import Topnav from "../Utils/Topnav";

const Projects = () => {
  const [refresh, setRefresh] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const username = JSON.parse(localStorage.getItem("user")).username;

  useEffect(() => {
    if (!username) return;
    const fetchData = async () => {
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contact/getContacts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username }),
        }
      );
      const resultData = await result.json();

      console.log("this is the data2", resultData.message.contacts);
      setContacts(resultData.message.contacts);
      setLoading(false);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [refresh, username]);

  const [optionsPopupIndex, setOptionsPopupIndex] = useState(-1);

  // if (!username) {
  //   return <Onboarding />;
  // }

  const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
  const [removePopupIndex, setRemovePopupIndex] = useState(-1);
  const [editContactIndex, setEditContactIndex] = useState(-1);

  const addProject = () => {
    setShowAddProjectPopup(true);
  };

  const navigate = useNavigate();

  if (loading) {
    return <div className="w-full">Loading...</div>;
  }

  return (
    <div className="w-full">
      <div className="">
        {showAddProjectPopup && (
          <AddProjectPopup
            showPopup={showAddProjectPopup}
            setShowPopup={setShowAddProjectPopup}
            setRefresh={setRefresh}
          />
        )}
        {editContactIndex > -1 && (
          <AddProjectPopup
            showPopup={showAddProjectPopup}
            setShowPopup={setShowAddProjectPopup}
            editContactIndex={editContactIndex}
            setEditContactIndex={setEditContactIndex}
            setRefresh={setRefresh}
            contact={contacts[editContactIndex]}
          />
        )}
        {removePopupIndex > -1 && (
          <RemoveProjectPopup
            removePopupIndex={removePopupIndex}
            setRemovePopupIndex={setRemovePopupIndex}
            // project={table[currentStage][removePopupIndex]}
            contact={contacts[removePopupIndex]}
            setRefresh={setRefresh}
          />
        )}
      </div>

      <div className="flex flex-col">
        <Topnav
          pageTitle="Contacts"
          showBackButton={false}
          primaryButtonText="+ New Contact"
          primaryButtonOnClick={addProject}
        />

        <div className="flex flex-col gap-2 w-full mt-10">
          {contacts &&
            contacts.map((contact, index) => (
              <div className="">
                <div
                  className="flex justify-between
              border border-gray-200 rounded-lg p-4 w-full cursor-pointer
               hover:bg-gray-100 group h-[140px]"
                  // onClick={() => {
                  //   navigate(`/app/project/${contact.id}`);
                  //   console.log("trace 100");
                  // }}
                  onClick={() => setEditContactIndex(index)}
                >
                  <div className="flex gap-4">
                    <InitialsPicture
                      name={contact.name}
                      email={contact.email}
                    />
                    <div className="flex flex-col">
                      <p className="text-lg font-medium">{contact.name}</p>
                      <p className="">
                        {contact.email} {contact.phone && "• " + contact.phone}
                      </p>
                      <p className="">{contact.address}</p>
                      <p className="">{contact.notes}</p>
                    </div>
                  </div>

                  <div className="flex gap-8">
                    <TrashIcon
                      className="-ml-4 hidden group-hover:block cursor-pointer"
                      stroke="black"
                      fill="#ffffff"
                      width="30"
                      height="30"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRemovePopupIndex(index);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
