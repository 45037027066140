import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/App/Delivery/upload.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { ReactComponent as ChevronIcon } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { useHotkeys } from "react-hotkeys-hook";

const LightboxPopup = ({
  sceneIndex,
  photoIndex,
  setSceneIndex,
  setPhotoIndex,
  delivery,
}) => {
  let selectPrevCondition = sceneIndex !== 0 || photoIndex !== 0;
  let selectNextCondition =
    sceneIndex !== delivery?.contents.length - 1 ||
    photoIndex !== delivery?.contents[sceneIndex]?.photos.length - 1;
  useHotkeys("left", () => {
    if (selectPrevCondition) {
      selectPrevPhoto();
    }
  });
  useHotkeys("right", () => {
    if (selectNextCondition) {
      selectNextPhoto();
    }
  });
  const selectPrevPhoto = () => {
    if (photoIndex === 0) {
      setSceneIndex((prevSceneIndex) => prevSceneIndex - 1);
      setPhotoIndex(delivery?.contents[sceneIndex - 1]?.photos.length - 1);
    } else {
      setPhotoIndex((prevPhotoIndex) => prevPhotoIndex - 1);
    }
  };
  const selectNextPhoto = (e) => {
    if (photoIndex === delivery?.contents[sceneIndex]?.photos.length - 1) {
      setSceneIndex((prevSceneIndex) => prevSceneIndex + 1);
      setPhotoIndex(0);
    } else {
      setPhotoIndex((prevPhotoIndex) => prevPhotoIndex + 1);
    }
  };
  const imageRef = React.useRef(null);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
  }, [imageRef, photoIndex, sceneIndex]);

  console.log("imageHeight", imageHeight);

  return (
    // for some reason cycling through images would highlight it
    // select none added to prevent highlighting
    <div className="select-none z-50">
      <div className="fixed w-screen h-screen bg-white lg:bg-white/95 mx-auto top-0 left-0 z-20"></div>
      <p>{(sceneIndex !== 0 && photoIndex !== 0).toString()}</p>

      <div className="">
        <CrossIcon
          className="fixed top-0 right-0 cursor-pointer z-40 stroke-gray-400 w-10 h-10 lg:w-20 lg:h-20"
          onClick={() => {
            setSceneIndex(-1);
            setPhotoIndex(-1);
          }}
        />
      </div>

      {selectPrevCondition && (
        <div
          className={`fixed top-1/2 -translate-y-1/2 left-0 w-[5vw] lg:bg-transparent z-50`}
          style={{ height: imageHeight }}
          onClick={selectPrevPhoto}
        >
          <ChevronIcon
            className="fixed top-1/2 left-0 w-[5vw] h-[5vw] lg:w-20 lg:h-20
            
           rotate-180 cursor-pointer z-40 fill-gray-400"
          />
        </div>
      )}

      {selectNextCondition && (
        <div
          className={`fixed top-1/2 -translate-y-1/2 right-0 w-[5vw] lg:bg-transparent z-50`}
          style={{ height: imageHeight }}
          onClick={selectNextPhoto}
        >
          <ChevronIcon
            className="fixed top-1/2 right-0 w-[5vw] h-[5vw] lg:w-20 lg:h-20
            
           cursor-pointer z-40 fill-gray-400"
          />
        </div>
      )}

      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
        <div>
          <img
            src={delivery?.contents[sceneIndex]?.photos[photoIndex].url}
            alt="selected"
            className="min-w-[90vw] lg:min-w-0 lg:max-h-[80vh] lg:max-w-[60vw]"
            ref={imageRef}
          />
          <DownloadIcon
            stroke="#9BA3AF"
            className="hidden lg:block cursor-pointer w-8 h-8 lg:w-7 lg:h-7 mt-2"
            // onClick={() => setShowPopup(false)}
          />
        </div>
        {/* </div> */}
      </div>
      <div className="fixed bottom-0 -translate-y-1 z-40 rounded-lg flex w-full justify-end p-2">
        <a
          className=""
          download={delivery?.contents[sceneIndex]?.photos[photoIndex].url}
          href={delivery?.contents[sceneIndex]?.photos[photoIndex].url}
        >
          <DownloadIcon
            stroke="#9BA3AF"
            className=" cursor-pointer w-8 h-8 lg:w-20 lg:h-20"
            // onClick={() => setShowPopup(false)}
          />
          {/* <p>ok hrere</p> */}
        </a>
      </div>
    </div>
  );
};

export default LightboxPopup;
