import React, { useState, useEffect } from "react";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron.svg";
import { useNavigate } from "react-router-dom";

const Topnav = ({
  pageTitle,
  showBackButton,
  backButtonUrl,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between py-2 gap-2 sticky top-0 -mt-10 pt-10 bg-white z-10">
      <div className="flex gap-2 items-center mb-8">
        {showBackButton && (
          <ChevronIcon
            name="down arrow"
            className="rounded-lg cursor-pointer rotate-180"
            stroke="black"
            fill="black"
            width="25"
            height="25"
            onClick={() => navigate(backButtonUrl)}
          />
        )}
        <p className="text-xl font-medium">{pageTitle}</p>
      </div>
      <div className="flex gap-2 h-fit">
        {secondaryButtonText && (
          <button
            onClick={secondaryButtonOnClick}
            className="bg-brown hover:bg-beach text-black px-3 py-2 rounded-lg  text-sm font-medium"
          >
            {secondaryButtonText}
          </button>
        )}

        {primaryButtonText && (
          <button
            onClick={primaryButtonOnClick}
            className=" bg-brown hover:bg-beach text-black px-3 py-2 rounded-lg text-sm font-medium"
          >
            {primaryButtonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default Topnav;
