import React, { useEffect } from "react";

// Need to make sure editing works here
const IfElsePopup = ({ details, setDetails, isEditing }) => {
  useEffect(() => {
    if (isEditing) return;
    setDetails((prevDetails) => ({
      type: "if_else",
      condition: "form_1_id",
    }));
  }, [setDetails, isEditing]);

  return (
    <div className="flex gap-4 w-[500px]">
      <p>If</p>
      <select
        name="cars"
        id="cars"
        // TODO: add value={} to ensure autofill on editing
        onChange={(e) =>
          setDetails((prevDetails) => ({
            type: "if_else",
            condition: e.target.value,
            subject: prevDetails.subject,
          }))
        }
      >
        <option value="form_1_id">Form 1</option>
        <option value="form_2_id">Form 2</option>
        <option value="form_3_id">Form 3</option>
      </select>
      <p>is filled out</p>
    </div>
  );
};

export default IfElsePopup;
