import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Hero from "./Hero";
import PricingContent from "./PricingContent";
import Footer from "../Footer";

const Pricing = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [emailSubmitError, setEmailSubmitError] = React.useState(false);
  const addToWaitlist = async () => {
    if (!email.includes("@") || !email.includes(".")) {
      setMessage("Please enter a valid email address");
      setEmailSubmitError(true);
    } else {
      console.log(
        "this 2",
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/add`
      );
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );
      const data = await res.json();
      console.log("this is the data", data);
      setMessage(data.message);
      setEmailSubmitError(false);
    }
  };
  const { logout } = useAuth0();

  return (
    <div className="">
      <Hero />
      <PricingContent />
      <Footer />
    </div>
  );
};

export default Pricing;
