import React, { useMemo, useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayEnd from "./DisplayEnd";
import DisplayNode from "./DisplayNode";
import AddOptionsPopup from "./AddOptionsPopup";

import EditEventPopup from "./EditEventPopup/EditEventPopup";
import AddEmailsPopup from "./AddEmailsPopup";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron.svg";
import Topnav from "../Utils/Topnav";

const WorkflowBuilder = ({ workflowObject }) => {
  // const {param} = useState()
  const { workflowId } = useParams();
  const [popupIndex, setPopupIndex] = useState(null);
  const [popupDirection, setPopupDirection] = useState(null);
  const [title, setTitle] = useState("Interest Form Workflow");
  // const [selectedEventType, setSelectedEventType] = useState(null);

  // editEventPopupDetails = {nodeId} when we are editing event
  // editEventPopupDetails = {selectedEventType} when we are adding an event
  const [editEventPopupDetails, setEditEventPopupDetails] = useState({});

  const username = JSON.parse(localStorage.getItem("user")).username;

  // const rootId = Date.now();
  // prevent rootId from changing on rerender
  // var rootId = useMemo(() => Date.now(), []);
  const [rootId, setRootId] = useState();
  console.log("this is rootId", rootId);

  const [tree, setTree] = useState();
  console.log("this tree", tree);

  useEffect(() => {
    // set workflow to tree being passed in from the top
    if (!workflowObject) return;
    console.log("useEffect 17");
    setTree(workflowObject);
    setRootId(1);
  }, [workflowObject]);

  useEffect(() => {
    const getWorkflow = async () => {
      // console.log("this is2", username, formId);
      if (!username) return;
      if (!workflowId) return; //do not fetch if no workflow exists
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/workflow/getWorkflow`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username, workflowId }),
        }
      );
      const data = await res.json();
      console.log("data2", data);
      if (data.ok) {
        setTree(data.message.workflow);
        setRootId(data.message.rootId);
        setTitle(data.message.title);
      }
    };
    getWorkflow();
  }, [username, workflowId, rootId]);

  // store left child as [leftChild, null]
  // store right child as [null, rightChild]
  function renderTree(nodeId) {
    const node = tree[nodeId];

    if (node === undefined) {
      return <DisplayEnd />;
    }

    var children = [];
    var arrows = [];

    // no children
    if (node.children.length === 0) {
      children.push(renderTree(undefined));
      arrows.push("straight");

      //only one child (no if else branch)
    } else if (node.children.length === 1) {
      children.push(renderTree(node.children[0]));
      // children.push(renderTree(undefined));
      arrows.push("straight");

      // if else branch with 1 or 2 children
    } else if (node.children.length === 2) {
      children.push(renderTree(node.children[0]));
      children.push(renderTree(node.children[1]));
      arrows.push("left");
      arrows.push("right");
    }
    console.log("wf builder tree", tree);

    return (
      <div className="flex flex-col">
        <DisplayNode
          nodeId={nodeId}
          node={node}
          arrows={arrows}
          popupIndex={popupIndex}
          setPopupIndex={setPopupIndex}
          tree={tree}
          setTree={setTree}
          popupDirection={popupDirection}
          setPopupDirection={setPopupDirection}
          setEditEventPopupDetails={setEditEventPopupDetails}
        />
        <div className="flex gap-10 justify-center">{children}</div>
      </div>
    );
  }
  const navigate = useNavigate();
  const saveWorkflow = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/workflow/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workflowId,
          workflow: tree,
          title,
          username,
          rootId: rootId,
          workflowEmails: workflowEmails,
        }),
      }
    );

    const data = await response.json();
    console.log("data", data);

    if (data.ok) {
      navigate("/app/workflows");
      alert("Workflow saved successfully");
    }
  };

  const [showAddEmailsPopup, setShowAddEmailsPopup] = useState(false);
  const [workflowEmails, setWorkflowEmails] = useState([]);
  return (
    <div className="w-full">
      <Topnav
        pageTitle="Workflow Builder"
        showBackButton={true}
        backButtonUrl="/app/workflows"
        primaryButtonText={workflowId ? "Save" : "Publish"}
        primaryButtonOnClick={saveWorkflow}
        secondaryButtonText="Add to workflow"
        secondaryButtonOnClick={() => setShowAddEmailsPopup(true)}
      />
      <div className="flex flex-col gap-10 mx-auto mt-10 items-center">
        <textarea
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="rounded-lg text-3xl px-3 text-center resize-none"
        />

        {!rootId ? (
          <button
            className="border-2 border-dashed p-2 mb-4 text-center rounded-lg hover:bg-gray-100"
            onClick={() => setPopupIndex(-1)}
          >
            <p>Choose starting point</p>
          </button>
        ) : (
          renderTree(rootId)
        )}

        {/* when selectedEventType is set, then show editEventPopup */}

        {Object.keys(editEventPopupDetails).length > 0 && (
          <EditEventPopup
            tree={tree}
            setTree={setTree}
            setRootId={setRootId}
            popupIndex={popupIndex}
            setPopupIndex={setPopupIndex}
            popupDirection={popupDirection}
            editEventPopupDetails={editEventPopupDetails}
            setEditEventPopupDetails={setEditEventPopupDetails}
          />
        )}

        {/* when selectedEventType is not set, but popupIndex is set then show AddOptionsPopup */}

        {Object.keys(editEventPopupDetails).length === 0 && popupIndex && (
          <div>
            <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-30"></div>

            <AddOptionsPopup
              tree={tree}
              setTree={setTree}
              setRootId={setRootId}
              setPopupIndex={setPopupIndex}
              nodeId={popupIndex}
              popupDirection={popupDirection}
              setPopupDirection={setPopupDirection}
              // editEventPopupDetails={editEventPopupDetails}
              setEditEventPopupDetails={setEditEventPopupDetails}
            />
          </div>
        )}
        {showAddEmailsPopup && (
          <AddEmailsPopup
            setShowAddEmailsPopup={setShowAddEmailsPopup}
            workflowEmails={workflowEmails}
            setWorkflowEmails={setWorkflowEmails}
          />
        )}
      </div>
    </div>
  );
};

export default WorkflowBuilder;
