import React from "react";

const JoinUs = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [emailSubmitError, setEmailSubmitError] = React.useState(false);
  const addToWaitlist = async () => {
    if (!email.includes("@") || !email.includes(".")) {
      setMessage("Please enter a valid email address");
      setEmailSubmitError(true);
    } else {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );
      const data = await res.json();
      console.log("this is the data", data);
      setMessage(data.message);
      setEmailSubmitError(false);
    }
  };
  return (
    <div className="join_us px-5 pt-20 pb-32" id="joinus">
      <div className="relative text-white px-10 py-12 mx-2 md:w-3/5 md:mx-auto">
        <img
          src={require("../../assets/images/LandingPageRect.png")}
          alt="background"
          className="absolute top-0 left-0 z-10 h-full w-full"
        />

        <div className="z-20 relative">
          <div className="flex gap-20 items-center md:px-20 md:py-12">
            <div>
              <h1 className="text-2xl md:text-4xl font-semibold mb-6 z-30 md:leading-tight md:font-extrabold">
                Join the{" "}
                <span className="text-yellow">100 other photographers</span> who
                use PancakeCRM to deliver a great customer experience
              </h1>
              {/* <h1 className="text-sm md:text-lg mb-6 md:mb-12">
                * 7-day free trial. No credit card required.
              </h1> */}

              <div className="flex flex-col gap-4 md:flex-row md:gap-0 w-full">
                <input
                  type="text"
                  placeholder="Enter your work email"
                  className="rounded-lg md:rounded-tr-none md:rounded-br-none
                        
                        px-2 py-1 text-primary md:text-lg"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p
                  className={
                    emailSubmitError
                      ? "md:hidden text-red-500"
                      : "md:hidden text-yellow"
                  }
                >
                  {message}
                </p>
                <div
                  className=" border-yellow bg-yellow hover:bg-brown hover:border-purple
                         text-primary font-bold
                          cursor-pointer
                          border-y-2 rounded md:rounded-tl-none md:rounded-bl-none
                          px-4 py-1 
                          w-fit
                          mx-auto md:mx-0"
                  onClick={() => addToWaitlist(email)}
                >
                  Get Early Access
                </div>
              </div>
              <p
                className={
                  emailSubmitError
                    ? "text-red-500 hidden md:block mt-2"
                    : "text-yellow hidden md:block mt-2"
                }
              >
                {message}
              </p>
            </div>

            <div className="hidden md:block">
              <img
                src={require("../../assets/images/Angie_headshot.jpeg")}
                alt=""
                className="w-96 rounded-2xl -rotate-6"
              />
              <img
                src={require("../../assets/images/Ashley_headshot.jpeg")}
                alt=""
                className="w-96 rounded-2xl rotate-6 mx-12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
