import React, { useContext, useEffect, useState } from "react";

const FormFilledPopup = ({ details, setDetails, isEditing }) => {
  const username = JSON.parse(localStorage.getItem("user")).username;
  const [forms, setForms] = useState([]);
  useEffect(() => {
    if (isEditing) return;
    setDetails((prevDetails) => ({
      type: "form_submission",
      formId: "",
    }));
  }, [setDetails]);

  useEffect(() => {
    // const response =
    // TODO tomorrow: fetch the forms here and populate the options
    // when they click add, make sure it gets added
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/formBuilder/getForms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username }),
        }
      );
      const data = await response.json();
      console.log("data", data);
      setForms(data.message);
    };
    fetchData();
  }, []);

  return (
    <div className="flex gap-4 w-[500px]">
      <p>If</p>
      <select
        name="cars"
        id="cars"
        value={details.formId}
        onChange={(e) =>
          setDetails((prevDetails) => ({
            type: "form_submission",
            formId: e.target.value,
            formName: forms.find((form) => form.id === e.target.value).title,
          }))
        }
      >
        <option value="" disabled selected>
          Select a form
        </option>
        {forms?.map((form, index) => (
          <option value={form.id} name={form.title} key={index}>
            {form.title}
          </option>
        ))}
      </select>
      <p>is filled out</p>
    </div>
  );
};

export default FormFilledPopup;
