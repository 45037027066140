export function generateDOMElement(
  el,
  form,
  setFormState,
  pageIndex,
  setForm,
  formBuilderMode,
  fieldIndex
) {
  function onFormBuilderFieldChange(e) {
    setForm((prevForm) => {
      const newForm = { ...prevForm };
      const component = {
        ...prevForm.pages[pageIndex].components[fieldIndex],
        label: e.target.value,
      };
      // find field index insert a new component and remove the old component
      // effectively replacing it which is why the 1
      newForm.pages[pageIndex].components.splice(fieldIndex, 1, component);
      return newForm;
    });
  }
  let page = form.pages[pageIndex];
  let component;
  if (el.type === "textfield" || el.type === "emailfield") {
    component = (
      <div className="relative z-0 w-full mb-2 text-xl">
        {formBuilderMode ? (
          <input
            type="text"
            className="focus:outline-none focus:ring-0 placeholder:italic placeholder:text-xl w-full"
            placeholder="Type your question here"
            value={page.components[fieldIndex].label}
            onChange={onFormBuilderFieldChange}
          />
        ) : (
          <label for="name" className="text-lg lg:text-xl">
            {el.label}
          </label>
        )}
        <input
          type="text"
          name={el.name}
          // key={formState.length}
          onChange={(e) =>
            setFormState((prevFormState) => ({
              ...prevFormState,
              [e.target.name]: e.target.value,
            }))
          }
          placeholder=" "
          required
          className="pt-3 pb-2 block text-lg w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200 text-slate-500"
        />
      </div>
    );
  } else if (el.type === "numberfield") {
    component = (
      <div className="relative z-0 w-full text-xl mb-2">
        {formBuilderMode ? (
          <input
            type="text"
            className="w-full focus:outline-none focus:ring-0 placeholder:italic placeholder:text-xl"
            placeholder="Type your question here"
            value={page.components[fieldIndex].label}
            onChange={onFormBuilderFieldChange}
          />
        ) : (
          <label for="name" className="text-lg lg:text-xl">
            {el.label}
          </label>
        )}
        <input
          type="number"
          name={el.name}
          // key={formState.length}
          onChange={(e) =>
            setFormState((prevFormState) => ({
              ...prevFormState,
              [e.target.name]: e.target.value,
            }))
          }
          placeholder=" "
          required
          className="pt-3 pb-2 text-base block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200 text-slate-500"
        />
      </div>
    );
  } else if (el.type === "textareafield") {
    component = (
      <div className="relative z-0 w-full text-xl mb-2">
        {formBuilderMode ? (
          <input
            type="text"
            className="w-full focus:outline-none focus:ring-0 placeholder:italic placeholder:text-xl"
            placeholder="Type your question here"
            value={page.components[fieldIndex].label}
            onChange={onFormBuilderFieldChange}
          />
        ) : (
          <label for="name" className="text-lg lg:text-xl">
            {el.label}
          </label>
        )}
        <textarea
          name={el.name}
          // key={formState.length}
          onChange={(e) =>
            setFormState((prevFormState) => ({
              ...prevFormState,
              [e.target.name]: e.target.value,
            }))
          }
          required
          className="pt-3 pb-2 block text-base w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
        ></textarea>
      </div>
    );
  } else if (el.type === "datefield") {
    component = (
      <div className="relative z-0 w-full text-xl mb-2">
        {formBuilderMode ? (
          <input
            type="text"
            className="focus:outline-none focus:ring-0 placeholder:italic placeholder:text-xl w-full"
            placeholder="Type your question here"
            value={page.components[fieldIndex].label}
            onChange={onFormBuilderFieldChange}
          />
        ) : (
          <label for="name" className="text-lg lg:text-xl">
            {el.label}
          </label>
        )}
        <input
          type="date"
          name={el.name}
          // key={formState.length}
          onChange={(e) =>
            setFormState((prevFormState) => ({
              ...prevFormState,
              [e.target.name]: e.target.value,
            }))
          }
          required
          className="pt-3 pb-2 block w-full text-base px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200 text-slate-500"
        />
      </div>
    );
  }

  return component;
}
