import React from "react";

const Hero = () => {
  return (
    <div className="h-[50vh] md:h-[30vh] relative hero md:w-5/6 md:mx-auto">
      <div
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                    w-3/4 md:w-2/3 
                     flex flex-col items-center gap-4 md:gap-8 
                     text-center"
      >
        <h1 className="text-3xl md:text-4xl font-bold text-primary">
          Choose the best plan to scale your photography service
        </h1>
        <h1 className="text-base md:text-2xl mb-6 md:mb-12">
          Lifetime access offer ends on May 1st, 2023
        </h1>
      </div>
    </div>
  );
};

export default Hero;
