import React, { createContext, useState, useEffect, useContext } from "react";
// import { useAuth0 } from "@auth0/auth0-react";

// default value for context when you don't use the provider
// https://stackoverflow.com/questions/49949099/react-createcontext-point-of-defaultvalue
export const AppDetailsContext = createContext({
  username: "n/a",
  setUsername: () => {},
});

export const AppDetailsProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [appDetailsRefresh, setAppDetailsRefresh] = useState(false);

  // const { user, isAuthenticated, isLoading } = useAuth0();

  // useEffect(() => {
  //   console.log("useeffect12");
  //   async function fetchData() {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}/auth/getUserByEmail`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           email: user.email,
  //         }),
  //       }
  //     );
  //     const data = await response.json();
  //     console.log("appdetailsprovider data", data);
  //     console.log("appdetailsprovider data.user.username", data.user.username);
  //     setUsername(data.user.username);
  //   }
  //   console.log("this isAuthenticated", isAuthenticated);
  //   if (user) {
  //     fetchData();
  //   }
  // }, [isAuthenticated, user, appDetailsRefresh]);

  console.log("trace 9", user);
  // might want to do {user && ...}
  return (
    // when one of the values in the context changes, all the children will re-render
    <AppDetailsContext.Provider value={{ user, setUser, setAppDetailsRefresh }}>
      {children}
    </AppDetailsContext.Provider>
  );
};

export default AppDetailsProvider;
