import React, { useState } from "react";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SendEmailPopup = ({
  username,
  projectId,
  to,
  setRefresh,
  setShowSendEmailPopup,
}) => {
  const [subject, setSubject] = useState("");
  // bug body should be plain text but react-quill gives html
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/project/sendEmail`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          projectId,
          to,
          subject,
          body,
          htmlBody: body,
        }),
      }
    );

    const data = await response.json();
    if (data.ok) {
      setLoading(false);
      setRefresh((prevRefresh) => !prevRefresh);
      setShowSendEmailPopup(false);
    }

    console.log("data received", data);
  };

  const quillRef = React.useRef();

  console.log(quillRef.current);

  return (
    <div className="flex flex-col mb-10">
      <div className="flex px-4 items-center justify-between gap-2 border-t-2 border-x-2 rounded-t-md py-2">
        <div className="flex items-center gap-2">
          <p className="text-gray-500  text-sm font-semibold">To:</p>
          <p>{to}</p>
        </div>
        <CrossIcon
          stroke="black"
          width={35}
          height={35}
          className="float-right cursor-pointer"
          onClick={() => setShowSendEmailPopup(false)}
        />
      </div>
      <div className="flex items-center gap-2 border-t-2 border-x-2 px-4 py-2">
        <p className="text-gray-500  text-sm font-semibold ">Subject:</p>
        <input
          type="text"
          className="w-full focus:ring-0 border-transparent focus:outline-0"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="border-2 h-[200px] overflow-y-scroll">
        <ReactQuill
          theme="snow"
          value={body}
          onChange={setBody}
          ref={quillRef}
          style={{ height: "170px", border: "0px" }}
        />
      </div>
      {loading ? (
        <button
          className="bg-brown/75 p-2 rounded-b-md cursor-default"
          // onClick={sendEmail}
        >
          Sending...
        </button>
      ) : (
        <button
          className="bg-brown font-medium p-2 rounded-b-md"
          onClick={sendEmail}
        >
          Send
        </button>
      )}
    </div>
  );
};

export default SendEmailPopup;
