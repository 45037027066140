import React from "react";

const DisplayEnd = () => {
  return (
    <div className="w-[200px] flex  justify-center">
      <p className="text-black text-lg pt-2">Contact exits</p>
    </div>
  );
};

export default DisplayEnd;
