import React from "react";

const SuggestedPanel = ({ setMessage }) => {
  const sendAnEmail = "Send an email to jacob@gmail.com about...";
  const buildAWorkflow =
    "Build a workflow with 3 emails spaced 2 days apart. It should be about...";

  return (
    <div className="w-full h-full border-y-2 border-l-2 rounded-l-md bg-[#F5F5F5]">
      <h1 className="text-lg font-medium px-4 py-1 mb-4 mt-4">Suggested</h1>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col">
          <p className="font-medium text-base px-4 py-2 bg-gray-100">Emails</p>
          <p
            className="hover:bg-gray-200 px-4 py-4 cursor-pointer"
            onClick={() => setMessage(sendAnEmail)}
          >
            Send an email
          </p>
          <p
            className="hover:bg-gray-200 px-4 py-4 cursor-pointer"
            onClick={() => setMessage(buildAWorkflow)}
          >
            Build a workflow
          </p>
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-base px-4 py-2 bg-gray-100">Import</p>
          <p className="hover:bg-gray-200 px-4 py-4 cursor-pointer">
            Import contacts
          </p>
          <p className="hover:bg-gray-200 px-4 py-4 cursor-pointer">
            Import interactions
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuggestedPanel;
