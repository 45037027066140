import React, { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router-dom";

const Slide1 = ({ goToNextSlide }) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  console.log("here is user", user);

  const submit = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/editUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          username: user.username,
          name,
        }),
      }
    );
    const data = await response.json();
    console.log("onboarding data", data);
  };
  return (
    <div className="flex flex-col gap-10 w-full items-center justify-center">
      {/* <div> */}
      <h1 className="text-3xl text-center">Welcome to PancakeCRM</h1>
      <p className="text-lg">What's your business name</p>
      <input
        type="text"
        className="border-2"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="flex flex-col gap-6">
        <p className="text-red-600">{message}</p>
        {/* <button
          className="bg-brown hover:bg-beach text-black self-center text-sm hover:bg-primary font-semibold text-white rounded-lg w-fit px-4 py-2 mt-2 "
          onClick={submit}
        >
          Submit
        </button> */}
      </div>
      <div className="flex gap-2">
        <button
          className="bg-brown hover:bg-beach text-black self-center font-medium  rounded-lg w-fit px-4 py-2 mt-2 "
          onClick={async () => {
            await submit();
            await goToNextSlide();
          }}
        >
          Next
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Slide1;
