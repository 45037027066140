import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./a.css";
import DisplayForm from "./DisplayForm";
import { generateDOMElement } from "./generateDOMElement";
import SettingsPane from "./SettingsPane";
import FormUrlPopup from "./FormUrlPopup";
import Topnav from "../Utils/Topnav";

const FormBuilder = () => {
  const { formId } = useParams();
  console.log("this is the id", formId);

  const [form, setForm] = useState({
    pages: [
      {
        title: "Photography Scheduling Session",
        components: [
          {
            type: "textfield",
            name: Date.now(),
            label: "Enter your name",
          },
          {
            type: "emailfield",
            name: Date.now() + 1, //add the +1 because the names become identical if you don't
            label: "Enter your email",
            required: true,
          },
        ],
      },
    ],
  });

  const [formState, setFormState] = useState({});
  const [formUrl, setFormUrl] = useState();
  const [showPopup, setShowPopup] = useState();
  const [popupIndex, setPopupIndex] = useState(null);
  const [urlCopied, setUrlCopied] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const username = JSON.parse(localStorage.getItem("user")).username;

  useEffect(() => {
    console.log("fetching forms??");
    const getForm = async () => {
      console.log("this is2", username, formId);
      if (!username) return;
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/formBuilder/get`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username, formId }),
        }
      );
      const data = await res.json();
      console.log("data2", data);
      if (data.ok) {
        setForm(data.message);
      }
    };
    getForm();
  }, [username, formId]);

  const addField = (type, fieldIndex) => {
    setPopupIndex(null);
    var component;
    if (type === "textfield") {
      component = {
        type: "textfield",
        name: Date.now(),
        label: "",
      };
    } else if (type === "button") {
      component = { type: "button", name: Date.now(), label: "Button" };
    } else if (type === "emailfield") {
      component = {
        type: "emailfield",
        name: Date.now(),
        label: "Enter your Email",
      };
    } else if (type === "numberfield") {
      component = {
        type: "numberfield",
        name: Date.now(),
        label: "",
      };
    } else if (type === "datefield") {
      component = {
        type: "datefield",
        name: Date.now(),
        label: "",
      };
    } else if (type === "textareafield") {
      component = {
        type: "textareafield",
        name: Date.now(),
        label: "",
      };
    }

    console.log("in else", fieldIndex);
    setForm((prevForm) => {
      const newForm = { ...prevForm };
      newForm.pages[pageIndex].components.splice(fieldIndex + 1, 0, component);
      return newForm;
    });
  };

  const saveForm = async () => {
    // generate states
    // attach formState to elements
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/formBuilder/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // use form.id here and not formId because that is pulled from the url
        // and we can change the fields in the form and then publish it on /formBuilder
        body: JSON.stringify({ form, username, formId: form.id }),
      }
    );
    const data = await response.json();
    console.log("this is the data", data.message);
    if (data.ok) {
      setFormUrl(
        `${process.env.REACT_APP_FRONTEND_URL}/${data.message.username}/forms/${data.message.formId}`
      );
      setShowPopup(true);
      setUrlCopied(false);
      setForm((prevForm) => ({ ...prevForm, id: data.message.formId }));
    }
  };
  console.log("this is form", form);

  console.log(formState);

  return (
    <div className="w-full relative">
      <div className="flex flex-col gap-10">
        <Topnav
          pageTitle="Form Builder"
          showBackButton={true}
          backButtonUrl="/app/forms"
          primaryButtonText={formId ? "Save" : "Publish"}
          primaryButtonOnClick={saveForm}
        />
      </div>

      {showPopup && (
        <FormUrlPopup
          formUrl={formUrl}
          setShowPopup={setShowPopup}
          urlCopied={urlCopied}
          setUrlCopied={setUrlCopied}
        />
      )}

      <div className="flex flex-col gap-4 pt-10 w-full">
        <DisplayForm
          form={form}
          setForm={setForm}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setFormState={setFormState}
          formBuilderMode={true}
          addField={addField}
          popupIndex={popupIndex}
          setPopupIndex={setPopupIndex}
        />
      </div>
    </div>
  );
};

export default FormBuilder;
