import React from "react";
import "./VideoPopup.css";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const VideoPopup = ({ setShowVideo }) => {
  return (
    <div className="fixed w-full h-full z-50">
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
      <div className="fixed w-[95%] lg:w-3/4 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-30">
        <div className="flex flex-col gap-2">
          <CrossIcon
            width={40}
            height={40}
            className="ml-auto cursor-pointer stroke-gray-300 hover:stroke-white"
            onClick={() => setShowVideo(false)}
          />
          <YoutubeEmbed embedId="Nm2uKZ1VZFI" />
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
