import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "./quill.css";
const ProjectInteraction = ({ interaction, forms }) => {
  console.log("this is interaction", interaction);

  if (
    interaction.type === "email_sent" ||
    interaction.type === "email_received"
  ) {
    return (
      <div className="flex flex-col gap-2 px-4 py-4 border-[0.5px] border-black/20 rounded-lg">
        <div className="flex flex-col gap-4 mb-4">
          <p className="text-black/50">Email</p>
          {/* <p>From: {interaction.from}</p> */}
          <p>To: {interaction.to}</p>
        </div>
        <hr />
        <div className="flex flex-col gap-0 mt-4 mb-4">
          <p className="font-semibold mb-4">{interaction.subject}</p>

          <ReactQuill
            theme="snow"
            value={interaction.body}
            readOnly={true}
            modules={{ toolbar: false }}
            style={{ border: "none!important", marginLeft: "-15px" }}
          />
        </div>
      </div>
    );
  } else if (interaction.type === "form_submission") {
    // linking forms to form entries

    console.log("this is forms", forms);
    const form = forms.find((el) => el.id === interaction.formId);
    console.log("this is form", form);

    // formatting in
    // [{label: "label", entry:"entry"}, {label: "name", entry:"entry"}]

    // v1 uses form, v2 uses form.pages
    let formPages = form?.pages || form;
    const result = [];

    console.log("form pages", formPages);
    for (let j = 0; j < formPages.length; j++) {
      for (let i = 0; i < formPages[j].components.length; i++) {
        let fieldName = formPages[j].components[i].name; //1679697043515
        let fieldLabel = formPages[j].components[i].label; //Enter your name

        // ["Enter your name", "John Doe"]
        result.push({
          label: fieldLabel,
          entry: interaction.fieldEntries[fieldName],
        });
        // result[fieldLabel] = interaction.fieldEntries[fieldName]; //
      }
    }
    return (
      <div className="flex flex-col gap-2 px-4 py-4 border-[0.5px] border-black/20 rounded-lg">
        <div className="flex flex-col gap-4 mb-4">
          <p className="text-black/50">Form Submission</p>
        </div>
        <hr />
        <div className="flex flex-col gap-4 mt-4 mb-4">
          {result.map((el) => (
            <p>
              {el.label}: <span className="font-semibold">{el.entry}</span>
            </p>
          ))}
        </div>
      </div>
    );
  }
};

export default ProjectInteraction;
