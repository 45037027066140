import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
// import "./styles.css";

const BusinessTools = () => {
  const slider = [
    {
      icon: require("../../assets/icons/LandingPage/image-gallery.png"),
      text: "Image Gallery",
    },
    {
      icon: require("../../assets/icons/LandingPage/payments.png"),
      text: "Invoicing and Payments",
    },
    {
      icon: require("../../assets/icons/LandingPage/calendar.png"),
      text: "Scheduling",
    },
    {
      icon: require("../../assets/icons/LandingPage/workflow.png"),
      text: "Workflows",
    },
    {
      icon: require("../../assets/icons/LandingPage/organize.png"),
      text: "Organizing Client Contacts",
    },
    {
      icon: require("../../assets/icons/LandingPage/automation.png"),
      text: "Automation",
    },
    { icon: require("../../assets/icons/LandingPage/crm.png"), text: "CRM" },
    {
      icon: require("../../assets/icons/LandingPage/sales.png"),
      text: "Track Sales and Expenses",
    },
    {
      icon: require("../../assets/icons/LandingPage/funnel.png"),
      text: "Lead Management",
    },
  ];

  return (
    <div className=" business_tools h-[50vh] md:h-[50vh] flex flex-col justify-center ">
      <h1 className="text-2xl md:text-4xl font-bold text-center mb-10 md:mb-20 px-2">
        Essential Business Tools on{" "}
        <span className="text-purple">One Platform</span>
      </h1>
      <Splide
        options={{
          type: "loop",
          gap: "50px",
          drag: "free",
          arrows: false,
          pagination: false,
          perPage: 3,
          autoScroll: {
            pauseOnHover: false,
            pauseOnFocus: false,
            rewind: false,
            speed: 0.5,
          },
          mediaQuery: "min",
          breakpoints: {
            640: {
              perPage: 5,
            },
            1200: {
              perPage: 8,
            },
            1500: {
              perPage: 10,
            },
            1800: {
              perPage: 12,
            },
          },
        }}
        extensions={{ AutoScroll }}
      >
        {slider.map((el) => {
          return (
            <SplideSlide>
              <div className="w-[140px] h-[180px] bg-yellow relative rounded-lg">
                <div className="">
                  <img
                    src={el.icon}
                    alt="icon"
                    className="w-12 h-10 mx-auto
                              absolute top-[30%] left-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                  <p
                    className="text-sm text-center mt-2 text-white
                                absolute top-[50%] left-1/2 -translate-x-1/2 -translate-y-1"
                  >
                    {el.text}
                  </p>
                </div>
              </div>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
};

export default BusinessTools;
