import React, { useState, useEffect, useContext } from "react";
// import AddFolderPopup from "./AddFolderPopup";

import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import RemoveWorkflowPopup from "./RemoveWorkflowPopup";
// import { ReactComponent as Chevron } from "../../../assets/icons/cross.svg";
import Topnav from "../Utils/Topnav";

const WorkflowMenu = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [removePopupIndex, setRemovePopupIndex] = useState(-1);

  const [workflows, setWorkflows] = useState([]);

  const navigate = useNavigate();

  console.log("trace 20");
  const username = JSON.parse(localStorage.getItem("user")).username;
  console.log("this is username too", username);

  useEffect(() => {
    const getFolders = async () => {
      if (!username) return;
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/workflow/getWorkflows`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username }),
        }
      );
      const data = await res.json();
      console.log("data2", data);
      setWorkflows(data.message);
    };
    getFolders();
  }, [refresh, username]);

  const addFolder = () => {
    // setShowPopup(true);
    navigate("/app/workflows/builder");
  };
  console.log("workflows", workflows);
  return (
    <div className="w-full relative">
      {removePopupIndex > -1 && (
        <RemoveWorkflowPopup
          removePopupIndex={removePopupIndex}
          setRemovePopupIndex={setRemovePopupIndex}
          workflow={workflows[removePopupIndex]}
          setRefresh={setRefresh}
        />
      )}
      <div className="flex flex-col gap-10">
        <Topnav
          pageTitle="Workflows"
          showBackButton={false}
          primaryButtonText="+ New Workflow"
          primaryButtonOnClick={addFolder}
        />
        <div className="flex flex-wrap cursor-pointer gap-2">
          {workflows &&
            workflows.map((workflow, index) => (
              <div
                className="flex justify-between items-center
              border border-gray-200 rounded-lg p-4 w-full cursor-pointer
               hover:bg-gray-100 group h-[70px] 
        "
                onClick={() =>
                  navigate(`/app/workflows/builder/${workflow.id}`)
                }
              >
                <p>{workflow.title}</p>

                <div className="flex gap-8">
                  <TrashIcon
                    className="-ml-4 hidden group-hover:block cursor-pointer"
                    stroke="black"
                    fill="#ffffff"
                    width="30"
                    height="30"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemovePopupIndex(index);
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WorkflowMenu;
