import React, { useState } from "react";

const FeaturesSection = () => {
  const slider = [
    {
      menuOption: "Capture Leads",
      title: "Capture and Track your Leads Effectively",
      text: "Boost your client base as a professional photographer by organizing your leads with PancakeCRM. Communicate systematically with your client through personalized automated follow-up responses. Integrate PancakeCRM's Lead Capture Forms with your website as an embedded Form or dedicated landing page for maximum efficiency.",
      buttonText: "Get Early Access",
    },
    {
      menuOption: "Schedule sessions",
      title: "Integrate Your Google Calendar Avoid Double Booking",
      text: "Say goodbye to the headaches of scheduling conflicts, double booking, and calendar mismanagement. It's time to simplify your workflow and streamline your calendar management which automates reminders to your client. Our platform makes it effortless to integrate your Google calendar, set your availability, and schedule events with your clients. Whether you're booking a project, a mini-session, or even a video call, the process is easier than ever.",
      buttonText: "Get Early Access",
    },
    {
      menuOption: "Communicate Via Email",
      title: "Collect, Arrange, and Communicate with Your Client in One Place",
      text: "Are you using multiple emails to communicate with your clients? Streamline the process for you and your clients by collecting client information, arranging their contacts, and communicating with them in one place. This will simplify your process while also avoiding the mistake of forgetting when the last time you communicated with your client. You’ll even have email templates you can use to avoid having to rewrite every time. Need to schedule send, you can do that too.",
      buttonText: "Get Early Access",
    },
    {
      menuOption: "Send Invoices",
      title: "Get Paid Online",
      text: "Cashapp or Venmo? Tired of looking like an unprofessional and cheap business? You deserve to present your business in the best light possible. Use a reliable and secure payment system. Not to mention, carrying cash or checks is becoming a thing of the past. With our integrated Stripe payment platform, you can easily manage your payments and invoicing all in one place, making it simple for both you and your clients.",
      buttonText: "Get Early Access",
    },
    {
      menuOption: "Deliver Photos",
      title: "Charge More for Your Digital Photos ",
      text: "Tired of juggling multiple subscriptions for image delivery and project management? Streamline your process and save money. Manage your projects and deliver stunning image galleries all in one place, directly through PancakeCRM. Say goodbye to the hassle of managing separate subscriptions and hello to a simplified and efficient workflow.",
      buttonText: "Get Early Access",
    },
  ];
  const [sliderPos, setSliderPos] = useState(0);

  return (
    <div className="features_section h-[90vh] flex items-center">
      <div className="md:w-2/3 mx-auto">
        <h1 className="text-2xl md:text-4xl font-bold text-center mb-10 md:mb-20 px-2">
          The Only <span className="text-purple">Business Partner </span>You
          Need
        </h1>

        <div className="hidden md:flex justify-around mb-10 gap-10 text-lg font-semibold">
          {slider.map((item, index) => (
            <div key={index}>
              <p
                className={
                  "cursor-pointer px-2 md:text-xl" +
                  (index === sliderPos
                    ? " underline decoration-yellow underline-offset-8 decoration-4"
                    : " text-gray-400")
                }
                onClick={() => setSliderPos(index)}
              >
                {item.menuOption}
              </p>
            </div>
          ))}
        </div>
        <div className="flex gap-14 justify-center items-center mb-10 md:hidden">
          <img
            src={require("../../assets/icons/iphone-chevron.png")}
            className="rotate-180 w-8 cursor-pointer"
            alt="back arrow"
            onClick={() =>
              setSliderPos((prevSliderPos) =>
                prevSliderPos - 1 >= 0 ? prevSliderPos - 1 : slider.length - 1
              )
            }
          />
          <p className="text-lg text-center font-medium w-[170px] ">
            {slider[sliderPos]["menuOption"]}
          </p>
          <img
            src={require("../../assets/icons/iphone-chevron.png")}
            className="w-8 cursor-pointer"
            alt="forward arrow"
            onClick={() =>
              setSliderPos((prevSliderPos) =>
                prevSliderPos + 1 >= slider.length ? 0 : prevSliderPos + 1
              )
            }
          />
        </div>
        <div className="px-5 mx-auto">
          <div className="bg-lightpink rounded-3xl py-4 px-6 md:py-20 md:px-16 h-[350px]">
            <h1 className="text-xl md:text-2xl font-semibold mb-5 text-slate-800">
              {slider[sliderPos]["title"]}
            </h1>
            <p className="text-slate-600 mb-6 md:text-xl">
              {slider[sliderPos]["text"]}
            </p>
            {/* <div
              className="flex items-center gap-2 w-fit
                         text-sm font-semibold
                        border-2 border-slate-900
                        rounded-lg
                        px-4 py-2
                        hover:bg-primary hover:text-white
                        cursor-pointer"
            >
              <a href="#joinus">{slider[sliderPos]["buttonText"]}</a>
              <img
                src={require("../../assets/icons/iphone-up-arrow-black.png")}
                className="w-6 rotate-90 "
                alt="forward arrow"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
