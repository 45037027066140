import React, { useState } from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import EditEventPopup from "./EditEventPopup/EditEventPopup";
const AddOptionsPopup = ({
  tree,
  setTree,
  setPopupIndex,
  nodeId,
  setRootId,
  popupDirection,
  setPopupDirection,
  setEditEventPopupDetails,
}) => {
  return (
    <div className=" bg-gray-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40">
      <div>
        <div className="flex justify-end bg-gray-200 px-2 py-2">
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer"
            onClick={() => {
              setPopupIndex(null);
              setPopupDirection(null);
            }}
          />
        </div>
        <div className="flex flex-col gap-2 justify-center px-10 py-10">
          <p className="font-semibold mb-2">Select an action</p>

          {nodeId === -1 ? (
            <>
              <div
                className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
                onClick={() =>
                  setEditEventPopupDetails({
                    selectedEventType: "form_submission",
                  })
                }
              >
                <p>Form Filled out</p>
              </div>
              <div
                className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
                onClick={() =>
                  setEditEventPopupDetails({ selectedEventType: "send_email" })
                }
              >
                <p>Send an email</p>
              </div>
            </>
          ) : (
            <>
              <div
                className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
                onClick={() =>
                  setEditEventPopupDetails({ selectedEventType: "send_email" })
                }
              >
                <p>Send an email</p>
              </div>
              <div
                className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
                onClick={() =>
                  setEditEventPopupDetails({ selectedEventType: "delay" })
                }
              >
                <p>Delay</p>
              </div>
              {/* <div
              className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
              onClick={() => setEditEventPopupDetails("if_else")}
            >
              <p>If/Else</p>
            </div> */}
              <div
                className="flex flex-between gap-4 items-center cursor-pointer bg-gray-200 hover:bg-gray-100 px-6 py-4 rounded-lg"
                onClick={() =>
                  setEditEventPopupDetails({
                    selectedEventType: "move_to_stage",
                  })
                }
              >
                <p>Move to</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOptionsPopup;
