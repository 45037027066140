import React from "react";
import RenderEmail from "./RenderEmail";
import RenderWorkflow from "./RenderWorkflow";

const RenderMessageMaster = ({ message, index }) => {
  let renderedMessage;
  if (message.content.includes("<emailJson>")) {
    renderedMessage = <RenderEmail message={message} index={index} />;
  } else if (message.content.includes("<workflowJSON>")) {
    renderedMessage = <RenderWorkflow message={message} index={index} />;
  } else {
    renderedMessage = (
      <p className="text-base w-full whitespace-pre-line">{message.content}</p>
    );
  }

  return (
    <div
      key={index}
      className={`flex gap-4 px-5 py-10 ${
        message.role === "assistant" && "bg-gray-100"
      } `}
    >
      <div className="bg-gray-700 w-6 h-6 relative rounded-lg">
        <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white">
          {message.role === "assistant" ? "P" : "Y"}
        </p>
      </div>
      {renderedMessage}
    </div>
  );
};

export default RenderMessageMaster;
