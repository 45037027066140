import React, { useState } from "react";
import ContactPopup from "./ContactPopup";
const PricingContent = () => {
  const [plan, setPlan] = useState(null);
  return (
    <div>
      {plan && (
        <div>
          <ContactPopup setPlan={setPlan} plan={plan} />

          <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-20"></div>
        </div>
      )}
      <div className="w-5/6 mx-auto flex flex-col gap-8 mb-20">
        <div className="flex gap-4 flex-wrap">
          <div
            className="bg-gray-100 w-full md:w-1/3 h-[250px] rounded-lg
            grow shrink basis-0
      flex flex-col justify-center px-4 gap-6"
          >
            <div className="flex flex-col gap-0">
              <p className="text-secondary font-bold text-xl">Monthly Access</p>
              <p className=" italic font-semibold text-sm text-secondary invisible ">
                ok
              </p>
            </div>
            <div className="flex gap-1">
              <p className="text-lg font-semibold">$</p>
              <p className="text-4xl font-bold">30</p>
              <p className="text-lg self-end italic font-semibold">/month</p>
            </div>
            <button
              className="border-2 border-secondary hover:bg-gray-100 w-fit  px-4 py-2 rounded-lg  font-semibold mt-2"
              onClick={() => setPlan("monthly")}
            >
              Contact us
            </button>
          </div>
          <div
            className="bg-[#F3F4FF] w-full md:w-1/3 h-[250px] rounded-lg
            grow shrink basis-0
      flex flex-col justify-center px-4 gap-6"
          >
            <div className="flex flex-col gap-0">
              <p className="text-[#5F68DB] font-bold text-xl">
                Lifetime Access
              </p>
              <p className=" italic font-semibold text-sm text-secondary ">
                Offer ends May 1st
              </p>
            </div>
            <div className="flex gap-1">
              <p className="text-lg font-semibold">$</p>
              <p className="text-4xl font-bold">400</p>
              <p className="text-lg self-end italic font-semibold">/one time</p>
            </div>
            <button
              className="bg-brown hover:bg-beach text-black w-fit px-4 py-2 rounded-lg font-medium mt-2"
              onClick={() => setPlan("lifetime")}
            >
              Contact us
            </button>
          </div>
          <div
            className="bg-gray-100 w-full md:w-1/3 h-[250px] rounded-lg
            grow shrink basis-0
      flex flex-col justify-center px-4 gap-6"
          >
            <div className="flex flex-col gap-0">
              <p className="text-secondary font-bold text-xl">Annual Access</p>
              <p className=" italic font-semibold text-sm text-secondary invisible ">
                ok
              </p>
            </div>
            <div className="flex gap-1">
              <p className="text-lg font-semibold">$</p>
              <p className="text-4xl font-bold">300</p>
              <p className="text-lg self-end italic font-semibold">/year</p>
            </div>
            <button
              className="border-2 border-secondary hover:bg-gray-100 w-fit  px-4 py-2 rounded-lg  font-semibold mt-2"
              onClick={() => setPlan("annual")}
            >
              Contact us
            </button>
          </div>
        </div>

        <h1 className="text-2xl md:text-4xl font-bold text-primary mx-auto mt-14">
          All Plans Include
        </h1>
        <div className="flex flex-wrap bg-gray-100 rounded-lg md:justify-around gap-10 px-4 py-14">
          <div className="flex flex-col gap-4">
            <h2 className="font-semibold text-secondary text-2xl">
              Automations
            </h2>
            <ul className=" list-disc text-secondary text-lg font-medium ml-5 flex flex-col gap-4">
              <li className="">Lead Capture Forms</li>
              <li className="">Workflow Builder</li>
              <li className="">Trigger-based Emailing</li>
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="font-semibold text-secondary text-2xl">Payments</h2>
            <ul className=" list-disc text-secondary text-lg font-medium ml-5 flex flex-col gap-4">
              <li className="">Stripe Integration</li>
              <li className="">Collect Payments from your clients</li>
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="font-semibold text-secondary text-2xl">
              Photo Delivery
            </h2>
            <ul className=" list-disc text-secondary text-lg font-medium ml-5 flex flex-col gap-4">
              <li className="">Watermark your photos</li>
              <li className="">Sell digital files</li>
              <li className="">100GB storage</li>
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="font-semibold text-secondary text-2xl">
              Customer Service
            </h2>
            <ul className=" list-disc text-secondary text-lg font-medium ml-5 flex flex-col gap-4">
              <li className="">30 min customer support</li>
              <li className="">Workflow Builder</li>
              <li className="">We fulfill your feature requests</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingContent;
