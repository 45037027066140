import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, logoutUser } from "../../redux/user";

import MobileNavMenu from "./MobileNavMenu";
import { useAuth0 } from "@auth0/auth0-react";
import { GoogleLogin, googleLogout } from "@react-oauth/google";

import { AppDetailsContext } from "../../AppDetailsProvider";

import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false);
  // const user = false;
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  // const { user } = useSelector((state) => state.user);
  // const dispatch = useDispatch();
  console.log("here is the user", user);

  const createOrGetUser = async (response) => {
    const { name, picture, sub, email } = jwt_decode(response.credential);

    const loggedInUser = {
      _id: sub,
      _type: "user",
      name: name,
      email: email,
      image: picture,
    };
    /* Logging the decoded token to the console. */
    // console.log(decoded);

    const fetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/createOrGetUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loggedInUser),
      }
    );

    const result = await fetchResponse.json();

    console.log("this is result", result);
    loggedInUser.username = result.message.username;

    // dispatch(setUser(loggedInUser));
    localStorage.setItem("user", JSON.stringify(loggedInUser));
    navigate("/app/home");
    // console.log("action dispatched");
    // use redux to persist state
  };

  return (
    <div className="">
      {showMobileNavMenu && (
        <MobileNavMenu
          showMobileNavMenu={showMobileNavMenu}
          setShowMobileNavMenu={setShowMobileNavMenu}
        />
      )}

      <div className="flex justify-between pt-7 px-5 md:pt-16 md:px-40 items-center">
        <div className="flex items-center gap-2 md:gap-4">
          <img
            src={require("../../assets/images/pancake-logo.png")}
            alt="PancakeCRM Logo"
            className="w-8 md:w-14 text-primary"
          />
          <p
            className="font-bold text-2xl md:text-3xl cursor-pointer"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            PancakeCRM
          </p>
        </div>
        <div className="hidden md:flex justify-around gap-8 text-secondary font-semibold">
          <a href="/" className="hover:text-[#4F35F6]">
            Home
          </a>
          {/* <p>Blog</p> */}
          <a href="/pricing" className="hover:text-[#4F35F6]">
            Pricing
          </a>
          <a href="/blog" className="hover:text-[#4F35F6]">
            Blog
          </a>

          {/* may want to do onError= (e) => clg(e) */}
        </div>

        <div>
          {user ? (
            <div>
              <div className="px-4 py-3 rounded-lg bg-brown hover:bg-beach text-black font-medium">
                <a href="/app/home" className="">
                  Go to app
                </a>
              </div>
            </div>
          ) : (
            <GoogleLogin
              onSuccess={(response) => createOrGetUser(response, setUser)}
              onError={() => console.log("Error")}
            />
          )}
        </div>

        {/* <p>Login</p> */}
        {/* <p>Sign up</p> */}

        <img
          src={require("../../assets/icons/iphone-menu.png")}
          alt="Menu"
          onClick={() =>
            setShowMobileNavMenu((prevShowMobileMenu) => !prevShowMobileMenu)
          }
          className="h-8 w-8 cursor-pointer md:hidden"
        />
      </div>
    </div>
  );
};

export default Navbar;
