import React, { useEffect, useState } from "react";
import DisplayForm from "./DisplayForm";
import { useParams } from "react-router-dom";

const Form = () => {
  const { username, formId } = useParams();

  const [form, setForm] = useState({
    id: "",
    title: "Form Title",
    components: [],
  });

  const [formState, setFormState] = useState({});
  const [submitMessage, setSubmitMessage] = useState({});
  const [loading, setLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/formBuilder/get`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            formId,
          }),
        }
      );
      // convert the data to json
      const data = await result.json();

      console.log("this is the data234", data);
      // set state with the result
      setForm(data.message);
      setLoading(false);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  async function handleSubmit() {
    console.log("Form submitted");
    const result = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/formBuilder/submit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // use form.id becase form is pulled from the backend => better for security
        body: JSON.stringify({
          username: username,
          form: form,
          fieldEntries: formState,
        }),
      }
    );
    // convert the data to json
    const data = await result.json();

    // set state with the result
    setSubmitMessage(data.message);
  }

  if (loading) {
    return <></>;
  }
  console.log("Here is the form: ", form);
  console.log("Here is the formState: ", formState);

  return (
    <div>
      {Object.keys(submitMessage).length !== 0 ? (
        <div
          className="mx-auto max-w-3xl px-6 py-12 bg-white border-0  sm:rounded-3xl text-center
                 flex flex-col gap-3 items-center"
        >
          <p className="text-4xl">Thank you!</p>
          <p className="text-xl font-light">
            Your message was sent successfully
          </p>
          <button
            className="bg-brown w-fit px-2 py-1 text-lg mt-4 rounded-lg"
            onClick={() => setSubmitMessage({})}
          >
            Send Another
          </button>
        </div>
      ) : (
        <DisplayForm
          form={form}
          setForm={setForm}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setFormState={setFormState}
          formBuilderMode={false}
          handleSubmit={handleSubmit}
          submitMessage={submitMessage}
        />
      )}
    </div>
  );
};

export default Form;
