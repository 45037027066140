import React from "react";

const Footer = () => {
  return (
    <div className="bg-slate-800 text-white text-center px-6 py-10">
      <p className="font-bold text-2xl mb-6">PancakeCRM</p>
      {/* <div className="flex flex-col gap-2 mb-6">
        <p>Home</p>
        <p>Blog</p>
        <p>About</p>
        <p>Pricing</p>
      </div> */}
      <p className="text-gray-200 mb-10">
        Contact us at{" "}
        <a href="mailto:hello@pancakecrm.com" className="text-blue-500">
          hello@pancakecrm.com
        </a>
      </p>
      <a className="text-gray-200 hover:text-blue-500" href="/privacy-policy">
        Privacy Policy
      </a>
      <p className="text-gray-200">Copyright © 2023 PancakeCRM Inc.</p>
      <p className="text-gray-200">All rights reserved.</p>
    </div>
  );
};

export default Footer;
