import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const RemoveWorkflowPopup = ({ setRemovePopupIndex, setRefresh, workflow }) => {
  const [stage, setStage] = useState();
  const [stages, setStages] = useState();
  const username = JSON.parse(localStorage.getItem("user")).username;

  const submit = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/workflow/removeWorkflow`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          workflowId: workflow.id,
        }),
      }
    );
    const data = await res.json();

    if (data.ok) {
      setRemovePopupIndex(-1);
      setRefresh((prevRefresh) => !prevRefresh);
    }
  };

  return (
    <div>
      <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-20"></div>

      <div className="bg-gray-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
        <div className="flex justify-between items-center bg-gray-200 px-2 py-2 rounded-t-md w-[600px]">
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer invisible"
            onClick={() => setRemovePopupIndex(-1)}
          />
          <p className="font-semibold">Remove workflow</p>
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer"
            onClick={() => setRemovePopupIndex(-1)}
          />
        </div>
        <div className="flex flex-col gap-0 justify-center px-20 py-10">
          <p className="text-base text-primary w-[400px]">
            Are you sure you want to remove the workflow{" "}
            <span className="italic">{workflow.title}</span>?
          </p>

          <div className="self-end flex gap-2">
            <button
              className="hover:bg-gray-100 text-sm font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={() => setRemovePopupIndex(-1)}
            >
              Cancel
            </button>
            <button
              className="bg-brown text-sm hover:bg-beach font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={submit}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveWorkflowPopup;
