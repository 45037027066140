import React from "react";

const InitialsPicture = ({ name, email }) => {
  console.log("this is name", name);
  let initials = "";
  let toSplit = name || email;
  try {
    initials = toSplit
      .split(" ")
      .map((word) => word[0].toUpperCase())
      .join("");
  } catch (e) {
    console.log("error in InitialsPicture", e);
  }

  return (
    <div className="">
      <div className="flex items-center justify-center h-16 w-16 rounded-full bg-gray-200">
        <p className="text-gray-500 text-lg font-medium">{initials}</p>
      </div>
    </div>
  );
};

export default InitialsPicture;
