import React from "react";

const Blog = () => {
  return (
    <div className="h-full">
      <iframe
        src="https://bitangaproductions.editorx.io/pancakecrm"
        className="top-0 left-0 bottom-0 right-0 w-full h-screen border-0 m-0 p-0 overflow-hidden z-50"
        // style={"position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"}
        title="blog"
      ></iframe>
    </div>
  );
};

export default Blog;
