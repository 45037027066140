import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "../../../assets/icons/App/Delivery/upload.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const CoverPhotoPopup = ({ folderId, setRefresh, setShowCoverPhotoPopup }) => {
  const [files, setFiles] = useState([]);
  const username = JSON.parse(localStorage.getItem("user")).username;

  const { isDragActive, getRootProps, getInputProps, fileRejections } =
    useDropzone({
      accept: {
        "image/*": [],
      },
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        setFiles((prevFiles) => {
          const newFiles = acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );

          return [...prevFiles, ...newFiles];
        });
      },
    });

  console.log("here are the files", files);
  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div className="flex flex-col items-center gap-2 w-[100px]">
        <img
          src={file.preview}
          className={`w-[100px] h-[100px] object-cover rounded-lg border-2`}
          alt="uploaded pic"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <p>{file.name}</p>
      </div>
    </div>
  ));

  const addPhotosToFolder = () => {
    const addPhotos = async () => {
      console.log("uploading photos to folder", files);

      const formData = new FormData();

      formData.append("username", username);
      formData.append("folderId", folderId);

      // append many files to the same key to create an array of files
      files.forEach((file, index) => formData.append(`file`, files[0]));

      console.log("this is formData", ...formData);
      var response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/delivery/setCoverPhoto`,
        {
          method: "POST",
          body: formData,
        }
      );

      var result = await response.json();

      if (result.ok) {
        setFiles([]);
        setRefresh((prev) => !prev);
      }
    };

    addPhotos();
    setShowCoverPhotoPopup(false);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div>
      <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-20"></div>

      <div className="bg-gray-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
        <div className="w-[1000px]">
          <div className="flex justify-between items-center bg-gray-200 px-2 py-2 rounded-t-md">
            <CrossIcon
              stroke="black"
              width={35}
              height={35}
              className="float-right cursor-pointer invisible"
              onClick={() => setShowCoverPhotoPopup(false)}
            />
            <p className="font-semibold">Select Cover Photo</p>
            <CrossIcon
              stroke="black"
              width={35}
              height={35}
              className="float-right cursor-pointer"
              onClick={() => setShowCoverPhotoPopup(false)}
            />
          </div>

          <div className="w-full flex flex-col gap-16 justify-center items-center p-7">
            {files.length > 0 && (
              <div className="flex flex-col gap-5 w-full px-4 py-4 rounded-lg">
                <div className="flex justify-between">
                  <p className="text font-semibold text-2xl">
                    Confirm Selection
                  </p>
                </div>
                <div className="w-full flex gap-4 overflow-x-scroll">
                  {thumbs}
                </div>
                <button
                  className="bg-brown hover:bg-beach text-black mt-5 text-sm font-semibold rounded-lg w-fit px-4 py-2 "
                  onClick={addPhotosToFolder}
                >
                  Upload
                </button>
              </div>
            )}

            {files.length === 0 && (
              <div
                {...getRootProps()}
                className={`w-full h-[100px] p-4 
                    border-2 border-dashed border-primary rounded-lg
                    cursor-pointer ${isDragActive && "bg-gray-200"}`}
              >
                <input {...getInputProps()} />

                <div className="flex flex-col gap-1 items-center justify-center w-full h-full">
                  <UploadIcon
                    width={80}
                    height={80}
                    className=" cursor-pointer stroke-black"
                  />
                  <p className="text-base font-medium">
                    Please select a cover photo
                  </p>
                </div>
              </div>
            )}
            {fileRejections.length > 0 && (
              <p className="text-red-800 -mt-10">
                Please select only one cover photo
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverPhotoPopup;
