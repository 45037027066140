import React from "react";

const Testimonial = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [emailSubmitError, setEmailSubmitError] = React.useState(false);
  const addToWaitlist = async () => {
    if (!email.includes("@") || !email.includes(".")) {
      setMessage("Please enter a valid email address");
      setEmailSubmitError(true);
    } else {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );
      const data = await res.json();
      console.log("this is the data", data);
      setMessage(data.message);
      setEmailSubmitError(false);
    }
  };

  return (
    <div
      className="bg-primary text-white h-[80vh] md:h-[60vh]
                 px-10 flex items-center"
    >
      <div className="md:w-2/3 mx-auto ">
        <div className="flex gap-20 items-center">
          <div className="">
            <h1 className="text-2xl md:text-4xl font-semibold mb-6">
              Learn how Rafael with Bitanga Productions used PancakeCRM to build
              a <span className="text-yellow">6-figure business.</span>
            </h1>
            <img
              src={require("../../assets/images/Rafael_headshot.png")}
              alt="Rafael"
              className="md:hidden w-32 h-32 rounded-3xl mx-auto mt-6 mb-6"
            />
            <p className="mb-6 md:text-xl">
              As a college student, Rafael built a 6-figure business
              photographing college graduates. “When it comes to managing a
              business, automation and workflow is the secret recipe. PancakeCRM
              does just that.”
            </p>
            <div className="flex flex-col gap-4 md:flex-row md:gap-0 w-full mb-2">
              <input
                type="text"
                placeholder="Enter your work email"
                className="rounded-lg md:rounded-tr-none md:rounded-br-none
                        
                        px-2 py-1 text-primary md:text-lg"
                onChange={(e) => setEmail(e.target.value)}
              />
              <p
                className={
                  emailSubmitError
                    ? "md:hidden text-red-500"
                    : "md:hidden text-yellow"
                }
              >
                {message}
              </p>
              <div
                className=" border-yellow bg-yellow hover:bg-brown hover:border-purple
                         text-primary font-bold
                          cursor-pointer
                          border-y-2 rounded md:rounded-tl-none md:rounded-bl-none
                          px-4 py-1 
                          w-fit
                          mx-auto md:mx-0"
                onClick={() => addToWaitlist(email)}
              >
                Get Early Access
              </div>
            </div>
            <p
              className={
                emailSubmitError
                  ? "text-red-500 hidden md:block"
                  : "text-yellow hidden md:block"
              }
            >
              {message}
            </p>
          </div>
          <img
            src={require("../../assets/images/Rafael_headshot.png")}
            alt="Rafael"
            className="hidden md:block w-64 h-64 rounded-3xl mx-auto mb-6"
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
