import React, { useState, useEffect, useContext } from "react";
import AddFolderPopup from "./AddFolderPopup";

import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import RemoveFormPopup from "./RemoveFormPopup";
import Topnav from "../Utils/Topnav";
// import { ReactComponent as Chevron } from "../../../assets/icons/cross.svg";

const FormMenu = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [removePopupIndex, setRemovePopupIndex] = useState(-1);
  const [forms, setForms] = useState([]);

  const navigate = useNavigate();

  console.log("trace 20");
  const username = JSON.parse(localStorage.getItem("user")).username;
  console.log("this is username too", username);

  useEffect(() => {
    const getFolders = async () => {
      if (!username) return;
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/formBuilder/getForms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username }),
        }
      );
      const data = await res.json();
      console.log("data2", data);
      setForms(data.message);
    };
    getFolders();
  }, [refresh, username]);

  const addFolder = () => {
    // setShowPopup(true);
    navigate("/app/forms/builder");
  };
  console.log("forms", forms);
  return (
    <div className="relative">
      <div className="">
        {showPopup && (
          <AddFolderPopup
            title="Add a Folder"
            heading="Type a folder name"
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setRefresh={setRefresh}
          />
        )}

        {removePopupIndex > -1 && (
          <RemoveFormPopup
            removePopupIndex={removePopupIndex}
            setRemovePopupIndex={setRemovePopupIndex}
            form={forms[removePopupIndex]}
            setRefresh={setRefresh}
          />
        )}
      </div>

      <div className="flex flex-col gap-10">
        <Topnav
          pageTitle="Forms"
          showBackButton={false}
          primaryButtonText="+ New Form"
          primaryButtonOnClick={addFolder}
        />

        <div className="flex flex-wrap cursor-pointer gap-2">
          {forms &&
            forms.map((form, index) => (
              <div
                className="flex justify-between items-center
              border border-gray-200 rounded-lg p-4 w-full cursor-pointer
               hover:bg-gray-100 group h-[70px] 
        "
                onClick={() => navigate(`/app/forms/builder/${form.id}`)}
              >
                <p>{form.pages[0].title}</p>

                <div className="flex gap-8">
                  <TrashIcon
                    className="-ml-4 hidden group-hover:block cursor-pointer"
                    stroke="black"
                    fill="#ffffff"
                    width="30"
                    height="30"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemovePopupIndex(index);
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FormMenu;
