import React, { useEffect } from "react";

const MoveToStagePopup = ({ details, setDetails, isEditing }) => {
  useEffect(() => {
    if (isEditing) return;
    setDetails((prevDetails) => ({
      type: "move_to_stage",
      stage: "stage_1",
    }));
  }, [setDetails]);

  return (
    <select
      name="cars"
      id="cars"
      value={details.stage}
      onChange={(e) =>
        setDetails((prevDetails) => ({
          type: "move_to_stage",
          stage: e.target.value,
        }))
      }
      className="w-[500px]"
    >
      <option value="stage_1">Stage 1</option>
      <option value="stage_2">Stage 2</option>
      <option value="stage_3">Stage 3</option>
    </select>
  );
};

export default MoveToStagePopup;
