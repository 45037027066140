import React, { useState } from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const AddProjectPopup = ({
  formUrl,
  setShowPopup,
  urlCopied,
  setUrlCopied,
}) => {
  const [codeCopied, setCodeCopied] = useState(false);
  const embedCode = `<iframe
  src="${formUrl}"
  frameborder="0"
  width="900"
  height="1000"
></iframe>`;

  return (
    <div>
      <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-20"></div>

      <div className="bg-gray-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
        <div className="flex justify-between items-center bg-gray-200 px-2 py-2 rounded-t-md w-[600px]">
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer invisible"
            onClick={() => setShowPopup(false)}
          />
          <p className="font-semibold">Form created</p>
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer"
            onClick={() => setShowPopup(false)}
          />
        </div>
        <div className="flex flex-col items-center pt-24 pb-24 gap-5">
          <p className="text-lg">Share the form</p>
          <div className="flex">
            <p className="bg-gray-100 rounded-l-md px-2 py-1">{formUrl}</p>
            <button
              className="bg-brown text-black font-medium hover:bg-beach rounded-r-md px-2 py-1 focus:bg-brown"
              onClick={() => {
                navigator.clipboard.writeText(formUrl);
                setUrlCopied(true);
              }}
            >
              {urlCopied ? "Copied!" : "Copy Link"}
            </button>
          </div>
          <p className="text-lg">Or embed the form in your website</p>
          <div className="flex flex-col gap-2 justify-between bg-gray-100 rounded-t-md">
            <p className="whitespace-pre text-left rounded-lg pt-2 px-2">
              {embedCode}
            </p>
            <button
              className="bg-brown text-black font-medium hover:bg-beach rounded-b-md px-2 py-1 focus:bg-brown"
              onClick={() => {
                navigator.clipboard.writeText(embedCode);
                setCodeCopied(true);
              }}
            >
              {codeCopied ? "Copied!" : "Copy Code"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjectPopup;
