import React, { useState } from "react";
import { generateDOMElement } from "./generateDOMElement";
import AddOptionsPopup from "./AddOptionsPopup";
import { ReactComponent as PlusCircledIcon } from "../../../assets/icons/plus-circled.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
const DisplayForm = ({
  form,
  setForm,
  pageIndex,
  setPageIndex,
  setFormState,
  formBuilderMode,
  addField,
  popupIndex,
  setPopupIndex,
  handleSubmit,
}) => {
  console.log("form here", form);
  const page = form.pages[pageIndex];

  const removePage = () => {
    setForm((prevForm) => {
      const newForm = { ...prevForm };
      newForm.pages.splice(pageIndex, 1);
      return newForm;
    });
    setPageIndex((prevPageIndex) =>
      prevPageIndex === 0 ? 0 : prevPageIndex - 1
    );
  };
  const addPage = () => {
    setForm((prevForm) => {
      const newForm = { ...prevForm };

      const newPage = {
        title: "Untitled Page",
        components: [],
      };
      newForm.pages.splice(pageIndex + 1, 0, newPage);

      return newForm;
    });
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
  };

  console.log("this is form", form);
  console.log("this is page index", pageIndex);
  return (
    <div className="flex flex-col">
      <div className={!formBuilderMode ? `pt-16 lg:pt-32` : ``}>
        <div className="mx-auto max-w-3xl border-0 sm:rounded-3xl p-5 lg:p-0 flex flex-col gap-2">
          {formBuilderMode && (
            <div className="flex gap-2 justify-end mb-20">
              {form.pages.length > 1 && (
                <button
                  className={`${
                    !formBuilderMode && "invisible"
                  } px-6 py-3 mt-6 lg:mt-10 text-base lg:text-md text-black font-medium transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-brown hover:bg-beach focus:outline-none`}
                  onClick={removePage}
                >
                  - Remove Page
                </button>
              )}
              <button
                className={`${
                  !formBuilderMode && "invisible"
                } px-6 py-3 mt-6 lg:mt-10 text-base lg:text-md text-black font-medium transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-brown hover:bg-beach focus:outline-none`}
                onClick={addPage}
              >
                + Add Page
              </button>
            </div>
          )}
          <div className="flex gap-4 justify-center text-center mb-2 lg:mb-8">
            {formBuilderMode ? (
              <input
                type="text"
                value={page.title}
                onChange={(e) =>
                  setForm((prevForm) => {
                    const newForm = { ...prevForm };
                    newForm.pages[pageIndex].title = e.target.value;
                    return newForm;
                  })
                }
                className={`w-full text-3xl mb-8 font-bold text-center focus:outline-none focus:ring-0 border-2 border-transparent ${
                  formBuilderMode && "hover:border-black hover:border-dashed"
                }`}
              />
            ) : (
              <h1 className="text-2xl lg:text-4xl mb-8 font-bold focus:outline-none focus:ring-0">
                {page.title}
              </h1>
            )}
          </div>
          {page.components.length === 0 && (
            <PlusCircledIcon
              className="mb-10 mx-auto cursor-pointer"
              width="30"
              height="30"
              stroke="#EF6C00"
              fill="white"
              onClick={() => setPopupIndex(-1)}
            />
          )}
          {popupIndex === -1 && (
            <AddOptionsPopup
              setPopupIndex={setPopupIndex}
              addField={addField}
              fieldIndex={-1}
            />
          )}
          <div id="form" novalidate className="flex flex-col">
            {page.components.map((el, index) => (
              <div className="group flex items-center">
                <div className=" flex flex-col items-center gap-6 cursor-pointer w-full">
                  <div
                    className={`flex items-center gap-2 w-full border-2 border-transparent ${
                      formBuilderMode &&
                      "group-hover:border-dashed group-hover:border-beach"
                    } py-2 rounded-lg`}
                  >
                    {generateDOMElement(
                      el,
                      form,
                      setFormState,
                      pageIndex,
                      setForm,
                      formBuilderMode,
                      index
                    )}
                  </div>

                  {formBuilderMode && (
                    <PlusCircledIcon
                      className="-mt-10 hidden group-hover:block"
                      width="30"
                      height="30"
                      stroke="black"
                      fill="white"
                      onClick={() => setPopupIndex(index)}
                    />
                  )}
                  {popupIndex === index && formBuilderMode && (
                    <AddOptionsPopup
                      setPopupIndex={setPopupIndex}
                      addField={addField}
                      fieldIndex={index}
                    />
                  )}
                </div>
                {formBuilderMode && !el.required && (
                  <TrashIcon
                    className="-ml-4 hidden group-hover:block cursor-pointer"
                    stroke="black"
                    fill="#ffffff"
                    width="30"
                    height="30"
                    onClick={() =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        components: [
                          ...prevForm.components.slice(0, index),
                          ...prevForm.components.slice(index + 1),
                        ],
                      }))
                    }
                  />
                )}
                {formBuilderMode && el.required && (
                  <p
                    className="-ml-2 text-3xl text-black bg-white hidden group-hover:block cursor-pointer p-0 items-center"
                    width="30"
                    height="30"
                  >
                    *
                  </p>
                )}
              </div>
            ))}
            <div className="flex justify-end">
              <div className="flex gap-2">
                {pageIndex > 0 && (
                  <button
                    id="button"
                    type="button"
                    className="px-6 py-3 mt-6 lg:mt-10 text-base lg:text-lg text-black font-medium transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-brown hover:bg-beach focus:outline-none"
                    // onClick={handleSubmit}
                    onClick={() =>
                      setPageIndex((prevPageIndex) => prevPageIndex - 1)
                    }
                  >
                    <ArrowIcon
                      name="up arrow"
                      className="rounded-lg cursor-pointer rotate-180"
                      stroke="black"
                      fill="black"
                      width="20"
                      height="20"
                    />
                  </button>
                )}
                {pageIndex < form.pages.length - 1 && (
                  <button
                    id="button"
                    type="button"
                    className="px-6 py-3 mt-6 lg:mt-10 text-base lg:text-lg text-black font-medium transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-brown hover:bg-beach focus:outline-none"
                    // onClick={handleSubmit}
                    onClick={() =>
                      setPageIndex((prevPageIndex) => prevPageIndex + 1)
                    }
                  >
                    <ArrowIcon
                      name="up arrow"
                      className="rounded-lg cursor-pointer"
                      stroke="black"
                      fill="black"
                      width="20"
                      height="20"
                    />
                  </button>
                )}
                {pageIndex === form.pages.length - 1 && (
                  <button
                    id="button"
                    type="button"
                    className="px-6 py-3 mt-6 lg:mt-10 text-base lg:text-lg text-black font-medium transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-brown hover:bg-beach focus:outline-none"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayForm;
