import React, { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router-dom";

const Onboarding = ({ goToNextSlide }) => {
  const [name, setName] = useState("");
  const [updatedUsername, setUpdatedUsername] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const username = JSON.parse(localStorage.getItem("user")).username;

  const submit = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/editUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          personName: name,
          username,
          updatedUsername,
        }),
      }
    );
    const data = await response.json();
    if (data.ok) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          username: updatedUsername,
        })
      );
      await goToNextSlide();
      // setAppDetailsRefresh((prev) => !prev);
    } else {
      setMessage(data.message);
    }
    console.log("onboarding data", data);
  };
  return (
    <div className="flex flex-col gap-10 w-full items-center justify-center">
      {/* <div> */}
      <h1 className="text-3xl text-center">Please enter your details</h1>
      <div className="flex flex-col gap-6">
        <div className="flex  justify-between gap-2">
          <p>Please enter your full name:</p>
          <input
            type="text"
            className="border-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex justify-between gap-2">
          <p>Pick a username:</p>
          <input
            type="text"
            className="border-2"
            value={updatedUsername}
            onChange={(e) => setUpdatedUsername(e.target.value)}
          />
        </div>
        <p className="text-red-600">{message}</p>
        <button
          className="bg-brown hover:bg-beach text-black self-center text-sm font-medium rounded-lg w-fit px-4 py-2 mt-2 "
          onClick={async () => {
            await submit();
          }}
        >
          Next
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Onboarding;
