import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import VideoPopup from "./VideoPopup";

const Hero = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [emailSubmitError, setEmailSubmitError] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const addToWaitlist = async () => {
    if (!email.includes("@") || !email.includes(".")) {
      setMessage("Please enter a valid email address");
      setEmailSubmitError(true);
    } else {
      console.log(
        "this 2",
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/add`
      );
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/waitlist/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );
      const data = await res.json();
      console.log("this is the data", data);
      setMessage(data.message);
      setEmailSubmitError(false);
    }
  };

  return (
    <div>
      {showVideo && <VideoPopup setShowVideo={setShowVideo} />}
      <div className="w-2/3 mx-auto flex flex-col items-center gap-8 text-center justify-center mt-32">
        <h1 className="text-5xl md:text-7xl font-semibold text-primary">
          <span className="text-purple">AI-Powered CRM</span> for Professional
          Photographers
        </h1>
        <h1 className="text-2xl md:text-3xl text-secondary mb-3">
          ChatGPT-powered CRM that saves you time. Capture leads, schedule
          sessions, send invoices, receive payments, and deliver photos.
        </h1>

        <div className="flex flex-col gap-2 md:gap-0 md:flex-row w-full md:justify-center items-center">
          <input
            type="text"
            placeholder="Enter your work email"
            className="rounded-lg md:rounded-tr-none md:rounded-br-none
                        border-2 md:border-r-0 border-primary 
                        px-2 py-1 md:px-4 md:py-2 text-xl"
            onChange={(e) => setEmail(e.target.value)}
          />
          <p
            className={
              emailSubmitError
                ? "md:hidden text-red-500"
                : "md:hidden text-yellow"
            }
          >
            {message}
          </p>
          <div
            className=" border-primary bg-primary hover:bg-brown hover:border-purple text-white 
                          cursor-pointer
                          rounded-lg md:rounded-tl-none md:rounded-bl-none
                          border-y-2
                          px-3 py-1 md:px-4 md:py-2 text-lg 
                          w-fit min-w-[170px]
                          mx-auto md:mx-0"
            onClick={() => addToWaitlist(true)}
          >
            Get Early Access
          </div>
        </div>

        <div
          className="relative mt-5 lg:mt-10 w-[140%] lg:w-full"
          onClick={() => setShowVideo(true)}
        >
          <img
            src={require("../../assets/images/dashboard_screenshot.png")}
            alt="dashboard screenshot"
            className="w-full lg:w-full rounded-lg border"
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <PlayIcon
              className="mx-auto py-2 pl-3 pr-2 lg:py-5 lg:pr-4 lg:pl-5
                        w-16 h-16 lg:w-24 lg:h-24
                        border-[5px] border-[#ccc6f7]
                        bg-[#4F35F6] rounded-full
                        fill-[#ccc6f7]
                        cursor-pointer
                        hover:scale-110
                        transition-all duration-300 ease-in-out
                        z-20 mb-1"
            />
            <div className="hidden lg:block bg-gray-800/80 py-2 px-14 rounded-full border border-gray-400 text-white">
              <p className="mx-auto text-center">
                Watch Rishi set up PancakeCRM
              </p>
              <div className="flex gap-2 justify-center">
                <p className="">3 min</p>
                <p className="text-[#ccc6f7]">Watch now</p>
              </div>
            </div>
          </div>
        </div>
        <p
          className={
            emailSubmitError
              ? "text-red-500 hidden md:block"
              : "text-yellow hidden md:block"
          }
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default Hero;
