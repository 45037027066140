import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppDetailsProvider from "./AppDetailsProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import store from "./redux/store";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <Auth0Provider
  //   domain="dev-c6gn5b42.us.auth0.com"
  //   clientId="cfYipfnuTpWKrUSe1dYZtwsbRxFI3YWb"
  //   authorizationParams={{
  //     redirect_uri: `${process.env.REACT_APP_FRONTEND_URL}/app/home`,
  //   }}
  //   screen_hint="signup"
  // >
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <AppDetailsProvider>
      <Provider store={store}>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </Provider>
    </AppDetailsProvider>
  </GoogleOAuthProvider>
  // </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
