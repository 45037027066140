import React, { useState, useEffect, useContext } from "react";

import Onboarding from "../Onboarding/Onboarding";
import OptionsPopup from "./OptionsPopup";
import AddProjectPopup from "./AddProjectPopup";
import RemoveProjectPopup from "./RemoveProjectPopup";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron.svg";
import AddContactPopup from "../Contacts/AddContactPopup";
import Topnav from "../Utils/Topnav";

const ProjectMenu = () => {
  const [table, setTable] = useState();
  const [pipelineStages, setPipelineStages] = useState();
  const [currentStage, setCurrentStage] = useState("all");
  const [refresh, setRefresh] = useState(false);
  const [onboarding, setOnboarding] = useState({});
  const [loading, setLoading] = useState(true);
  const [projectsEmail, setProjectsEmail] = useState("");
  const username = JSON.parse(localStorage.getItem("user")).username;

  const [optionsPopupIndex, setOptionsPopupIndex] = useState(-1);
  const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
  const [removePopupIndex, setRemovePopupIndex] = useState(-1);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const [isEditing, setIsEditing] = useState();

  useEffect(() => {
    // declare the async data fetching function
    if (!username) return;
    const fetchData = async () => {
      // get the data from the api
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/project/getProjects`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username }),
        }
      );
      // convert the data to json
      const resultData = await result.json();

      console.log("this is the data2", resultData.message);
      resultData.message.table.all = Object.values(
        resultData.message.table
      ).reduce((a, b) => a.concat(b), []);
      // resultData.message.pipelineStages.push("All");
      resultData.message.pipelineStages.splice(0, 0, {
        name: "All",
        id: "all",
      });
      setTable(resultData.message.table);
      setPipelineStages(resultData.message.pipelineStages);
      setOnboarding(resultData.message.onboarding);
      setLoading(false);

      // set state with the result
      // setForm(data.message);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [refresh, username]);

  if (table) {
    console.log("this is ", currentStage, table[currentStage]);
  }
  console.log("currentStage", currentStage);

  const navigate = useNavigate();

  if (loading) {
    return <div className="w-full">Loading...</div>;
  }

  console.log("this is onboarding", onboarding);

  if (!onboarding?.completed) {
    return <Onboarding />;
  }

  async function saveProjectMenuEdits() {
    const result = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/project/savePipelineStages`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          pipelineStages: pipelineStages.filter((el) => el.name !== "All"),
        }),
      }
    );
    // convert the data to json
    const resultData = await result.json();

    console.log("this is the data2", resultData.message);
    setIsEditing(false);
  }

  console.log("table", table);

  // let allProjects = Object.values(table);
  // let allLength = allProjects.map((el) => el.length).reduce((a, b) => a + b, 0);

  return (
    <div className="w-full">
      <div className="">
        {showAddProjectPopup && (
          <AddProjectPopup
            showPopup={showAddProjectPopup}
            setShowPopup={setShowAddProjectPopup}
            setRefresh={setRefresh}
            setShowAddContactPopup={setShowAddContactPopup}
            setProjectsEmail={setProjectsEmail}
          />
        )}
        {showAddContactPopup && (
          <AddContactPopup
            showPopup={showAddContactPopup}
            setShowPopup={setShowAddContactPopup}
            setRefresh={setRefresh}
            emailToSet={projectsEmail}
          />
        )}
        {optionsPopupIndex > -1 && (
          <OptionsPopup
            optionsPopupIndex={optionsPopupIndex}
            setOptionsPopupIndex={setOptionsPopupIndex}
            project={table[currentStage][optionsPopupIndex]}
            setRefresh={setRefresh}
            currentStage={currentStage}
          />
        )}

        {removePopupIndex > -1 && (
          <RemoveProjectPopup
            removePopupIndex={removePopupIndex}
            setRemovePopupIndex={setRemovePopupIndex}
            project={table[currentStage][removePopupIndex]}
            setRefresh={setRefresh}
            currentStage={currentStage}
          />
        )}
      </div>

      <div className="flex flex-col">
        <Topnav
          pageTitle="Projects"
          showBackButton={false}
          primaryButtonText="+ New Project"
          primaryButtonOnClick={() => setShowAddProjectPopup(true)}
          secondaryButtonText={!isEditing ? "Edit" : "Save"}
          secondaryButtonOnClick={
            !isEditing ? () => setIsEditing(true) : saveProjectMenuEdits
          }
        />

        <div className="flex justify-between gap-2">
          {/* <p>username: {username}</p> */}

          {pipelineStages &&
            pipelineStages.map((stage, index) => (
              <div className="relative w-full min-h-fit h-[150px]">
                {isEditing && stage.id !== "all" && (
                  <div>
                    <div className="absolute top-0 right-0 translate-x-1 -translate-y-1 my-2 mx-1 z-10">
                      <TrashIcon
                        className="cursor-pointer"
                        stroke="black"
                        fill="#ffffff"
                        width="30"
                        height="30"
                        onClick={() => {
                          const newStages = [...pipelineStages];
                          newStages.splice(index, 1);
                          setPipelineStages(newStages);
                        }}
                      />
                    </div>
                    <div className="absolute bottom-0 right-0 translate-x-1 -translate-y-1 my-1/2 mx-2 z-10">
                      <div className="flex gap-0">
                        <ChevronIcon
                          className="bg-gray-100 rounded-lg cursor-pointer rotate-180"
                          stroke="black"
                          fill="black"
                          width="20"
                          height="20"
                          onClick={() => {
                            const newStages = [...pipelineStages];
                            const temp = newStages[index];
                            newStages[index] = newStages[index - 1];
                            newStages[index - 1] = temp;
                            setPipelineStages(newStages);
                          }}
                        />
                        <ChevronIcon
                          className="bg-gray-100 rounded-lg cursor-pointer"
                          stroke="black"
                          fill="black"
                          width="20"
                          height="20"
                          onClick={() => {
                            const newStages = [...pipelineStages];
                            const temp = newStages[index];
                            newStages[index] = newStages[index + 1];
                            newStages[index + 1] = temp;
                            setPipelineStages(newStages);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`h-full top-0 left-0 border border-gray-200 rounded-lg p-4 w-full cursor-pointer hover:bg-gray-100
                ${currentStage === stage.id && "bg-gray-100"}`}
                  onClick={() => setCurrentStage(stage.id)}
                >
                  <div className="text-2xl font-medium text-gray-900">
                    <p>{table[stage.id] ? table[stage.id].length : 0}</p>
                  </div>
                  {isEditing ? (
                    <input
                      className="text-gray-600 text-lg mt-4 w-full bg-transparent"
                      value={stage.name}
                      onChange={(e) => {
                        const newStages = [...pipelineStages];
                        newStages[index].name = e.target.value;
                        setPipelineStages(newStages);
                      }}
                    />
                  ) : (
                    <div className="text-gray-600 text-lg mt-4">
                      {stage.name}
                    </div>
                  )}
                </div>
              </div>
            ))}
          {isEditing && (
            <div
              className="bg-gray-200 h-[140px] w-[200px] flex justify-center items-center rounded-lg cursor-pointer"
              onClick={() => {
                setPipelineStages((prevStages) => [
                  ...prevStages,
                  { id: Date.now().toString(), name: "New Stage" },
                ]);
              }}
            >
              <p className="text-lg">+</p>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2 w-full mt-10">
          {table &&
            table[currentStage] &&
            table[currentStage].map((project, index) => (
              <div
                className="flex justify-between items-center
              border border-gray-200 rounded-lg p-4 w-full cursor-pointer
               hover:bg-gray-100 group h-[70px]"
                onClick={() => {
                  navigate(`/app/project/${project.id}`);
                  console.log("trace 100");
                }}
              >
                <p className="">{project.name}</p>

                <div className="flex gap-8">
                  <button
                    className="px-2 py-2 bg-brown font-medium hover:bg-beach text-xs rounded-lg text-black hidden group-hover:block z-80"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("trace 200");
                      setOptionsPopupIndex(index);
                    }}
                  >
                    Change Stage
                  </button>

                  <TrashIcon
                    className="-ml-4 hidden group-hover:block cursor-pointer"
                    stroke="black"
                    fill="#ffffff"
                    width="30"
                    height="30"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemovePopupIndex(index);
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectMenu;
