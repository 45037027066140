import React, { useState, useEffect, useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";

import ProjectInteraction from "./ProjectInteraction";
import SendEmailPopup from "./SendEmailPopup";
import { ReactComponent as ChevronIcon } from "../../../../assets/icons/chevron.svg";

const Home = () => {
  const [project, setProject] = useState({ interactions: [] });
  const { projectId } = useParams();
  const [to, setTo] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [forms, setForms] = useState([]);

  const [showSendEmailPopup, setShowSendEmailPopup] = useState(false);

  const username = JSON.parse(localStorage.getItem("user")).username;

  useEffect(() => {
    if (!username) return;
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/project/getProject`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, projectId }),
        }
      );
      // convert the data to json
      const resultData = await result.json();

      console.log("this is the data2", resultData.message);

      const thisProject = resultData.message.project;
      thisProject.interactions = thisProject.interactions.reverse();

      setProject(thisProject);
      setTo(thisProject.clients[0]);
      setForms(resultData.message.forms);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [refresh, username]);

  // const [showOptionsPopup, setShowOptionsPopup] = useState(false);

  // const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);

  // const addProject = () => {
  //   setShowAddProjectPopup(true);
  // };

  const navigate = useNavigate();
  return (
    <div className="w-full ml-10 mt-10">
      {/* <SendEmailPopup /> */}

      <div className="flex flex-col gap-10">
        <div className="flex justify-between items-center py-2 mb-8">
          <div className="flex gap-2 items-center">
            <ChevronIcon
              name="down arrow"
              className="rounded-lg cursor-pointer rotate-180"
              stroke="black"
              fill="black"
              width="25"
              height="25"
              onClick={() => navigate("/app/home")}
            />
            <p className="text-xl font-medium">{project.name}</p>
          </div>
        </div>

        {showSendEmailPopup ? (
          <SendEmailPopup
            username={username}
            projectId={projectId}
            setRefresh={setRefresh}
            to={to}
            setShowSendEmailPopup={setShowSendEmailPopup}
          />
        ) : (
          <div
            className=" p-2 mb-2 w-[530px] cursor-pointer border-[0.5px] border-black/20 rounded-lg"
            onClick={() => setShowSendEmailPopup(true)}
          >
            <p>Reply to {project.clients && project.clients[0]}</p>
          </div>
        )}

        <div className="flex flex-col gap-2 w-fit rounded-lg mt-10">
          {/* <p className="text-lg"> Recent Activity</p> */}
          {project.interactions.map((interaction) => (
            <ProjectInteraction interaction={interaction} forms={forms} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
