import React, { useEffect } from "react";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import ReactQuill from "react-quill";

const SendEmailPopup = ({ details, setDetails, isEditing }) => {
  useEffect(() => {
    // console.log('this is edit', edit)
    if (isEditing) return;
    setDetails((prevDetails) => ({
      type: "send_email",
      body: "",
      subject: "",
    }));
  }, [setDetails]);

  const quillRef = React.useRef();

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2 border-t-2 border-x-2 px-4 py-2">
        <p className="text-gray-500  text-sm font-semibold">Subject:</p>
        <input
          type="text"
          className="w-full focus:ring-0 border-transparent bg-transparent focus:outline-0"
          value={details.subject}
          onChange={(e) =>
            setDetails((prevDetails) => ({
              type: "send_email",
              body: prevDetails.body,
              subject: e.target.value,
            }))
          }
        />
      </div>
      <div className="border-2 h-[200px] overflow-y-scroll">
        <ReactQuill
          theme="snow"
          value={details.body}
          onChange={(content) =>
            setDetails((prevDetails) => ({
              type: "send_email",
              body: content,
              subject: prevDetails.subject,
            }))
          }
          ref={quillRef}
          style={{ height: "170px", border: "0px" }}
        />
      </div>
    </div>
  );
};

export default SendEmailPopup;
