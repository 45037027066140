import React, { useState } from "react";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";

import { useNavigate } from "react-router-dom";
const Topnav = ({ username, id, user, delivery }) => {
  const [selectedSceneIndex, setSelectedSceneIndex] = useState();

  const navigate = useNavigate();
  async function getZipUrl() {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/delivery/downloadZip`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, deliveryId: id }),
      }
    );
    const data = await res.json();
    console.log("data2", data);
    navigate(data.message.url);
  }

  return (
    <div className="sticky top-0 flex gap-12 pt-3 pb-3 lg:pb-0 px-10 z-40 bg-white border-b">
      <img
        className="hidden lg:block h-16 my-5"
        src={user?.settings?.logo}
        alt="logo"
      />

      <div className="flex gap-7 items-center justify-center w-full">
        {delivery &&
          delivery.contents.map((scene, index) => (
            <div
              key={index}
              className={`${
                index === selectedSceneIndex
                  ? "border-black"
                  : "border-transparent text-gray-400"
              } flex border-b-2 cursor-pointer text-lg h-full`}
            >
              <a
                href={"#" + index}
                className="text-sm lg:text-lg my-auto hover:text-black"
                onClick={() => setSelectedSceneIndex(index)}
              >
                {scene.name}
              </a>
            </div>
          ))}

        <DownloadIcon
          stroke="black"
          // width={35}
          // height={30}
          className="cursor-pointer ml-auto w-5 h-5 lg:w-7 lg:h-7"
          onClick={() => window.open(delivery.zipUrl, "_blank")}
        />
        {/* <p>icon2</p> */}
      </div>
    </div>
  );
};

export default Topnav;
