import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { AppDetailsContext } from "../../../AppDetailsProvider";
const AddProjectPopup = ({
  title,
  heading,
  showPopup,
  setShowPopup,
  setRefresh,
  setShowAddContactPopup,
  setProjectsEmail,
}) => {
  const [projectName, setProjectName] = useState("");
  const [client, setClient] = useState("");
  const username = JSON.parse(localStorage.getItem("user")).username;

  const submit = async () => {
    console.log("this is username", username);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/project/addProject`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          projectName,
          client,
        }),
      }
    );
    const data = await res.json();

    if (data.ok) {
      setShowPopup(false);
      setRefresh((prevRefresh) => !prevRefresh);
      setShowAddContactPopup(true);
      setProjectsEmail(client);
    }
  };

  return (
    <div>
      <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-20"></div>

      <div className="bg-gray-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
        <div className="flex justify-between items-center bg-gray-200 px-2 py-2 rounded-t-md w-[600px]">
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer invisible"
            onClick={() => setShowPopup(false)}
          />
          <p className="font-semibold">Add a Project</p>
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer"
            onClick={() => setShowPopup(false)}
          />
        </div>
        <div className="flex flex-col gap-0 justify-center px-20 py-10">
          <div>
            <p className="text-base text-primary">Enter Project Name</p>

            <input
              type="text"
              className="border border-gray-300 rounded-lg px-2 py-2 mt-4 w-full"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Rishi's project"
            />
          </div>
          <div className="mt-6">
            <p className="text-base text-primary">Enter Client Email</p>

            <input
              type="text"
              className="border border-gray-300 rounded-lg px-2 py-2 mt-4 w-full"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              placeholder="hello@gmail.com"
            />
          </div>

          <div className="self-end flex gap-2">
            <button
              className="hover:bg-gray-100 text-sm font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
            <button
              className="bg-brown text-sm hover:bg-beach font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={submit}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjectPopup;
