import React, { useEffect, useState, useRef } from "react";

const RenderEmail = ({ message, index }) => {
  const [emailSentStatus, setEmailSentStatus] = useState("not_sent");
  const [email, setEmail] = useState({});

  const username = JSON.parse(localStorage.getItem("user")).username;

  const parseEmail = (s) => {
    let startPos = s.search("<emailJson>") + "<emailJson>".length;
    let endPos = s.search("</emailJson>");
    let slicedString = s.slice(startPos, endPos);
    return JSON.parse(slicedString);
  };

  useEffect(() => {
    console.log("in useEffect");
    setEmail(parseEmail(message.content));
  }, []);

  const sendEmail = async () => {
    setEmailSentStatus("sending");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/project/sendEmail`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fromMrPancake: true,
          username: username,
          to: email.to,
          subject: email.subject,
          body: email.body,
        }),
      }
    );
    const data = await response.json();
    console.log("this is data", data);
    if (data.ok) {
      setEmailSentStatus("sent");
    }
  };

  const textRef = useRef();

  const onChangeHandler = function (e) {
    const target = e.target;
    textRef.current.style.height = "30px";
    textRef.current.style.height = `${target.scrollHeight}px`;
    setEmail((prevEmail) => ({
      ...prevEmail,
      body: e.target.value,
    }));
  };

  try {
    return (
      <div className="w-full whitespace-pre-line flex flex-col gap-2">
        <div className="flex gap-2">
          <p>To:</p>
          <input
            type="text"
            value={email.to}
            onChange={(e) =>
              setEmail((prevEmail) => ({
                ...prevEmail,
                to: e.target.value,
              }))
            }
            className="w-full rounded-lg px-1"
          />
        </div>

        <div className="flex gap-2">
          <p>Subject:</p>
          <input
            type="text"
            className="w-full rounded-lg px-1"
            value={email.subject}
            onChange={(e) =>
              setEmail((prevEmail) => ({
                ...prevEmail,
                subject: e.target.value,
              }))
            }
          />
        </div>
        <div className="flex gap-2">
          <p>Body:</p>
          <textarea
            ref={textRef}
            className="w-full rounded-lg px-1"
            type="text"
            value={email.body}
            onChange={onChangeHandler}
          />
        </div>

        <div className="flex justify-center">
          {emailSentStatus === "not_sent" && (
            <button
              className="bg-brown hover:bg-beach px-2 py-1 font-medium rounded-lg"
              onClick={sendEmail}
            >
              Send
            </button>
          )}
          {emailSentStatus === "sending" && (
            <button
              className="bg-brown hover:bg-beach/75 p-2 rounded-lg"
              onClick={sendEmail}
              disabled
            >
              Sending...
            </button>
          )}
          {emailSentStatus === "sent" && (
            <button
              className="bg-brown hover:bg-beach p-2 rounded-lg"
              onClick={sendEmail}
              disabled
            >
              Sent!
            </button>
          )}
        </div>
      </div>
    );
  } catch (err) {
    return (
      <p className="text-base w-full whitespace-pre-line">
        Please try again with a different prompt
      </p>
    );
  }

  // console.log("this is parseEmail", parseEmail(message));
  // console.log("this is parseEmail 2", JSON.parse(parseEmail(message)));
};

export default RenderEmail;
