import React from "react";
import { useNavigate } from "react-router-dom";

const YC = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [message, setMessage] = React.useState("");

  const navigate = useNavigate();
  const handleSubmit = () => {
    if (username === "yc" && password === "sanfran") {
      // this is the username for pancakecrm
      localStorage.setItem(
        "user",
        JSON.stringify({ username: "1680153990357" })
      );
      navigate("/app/home");

      // no await - yc should be able to log in if server fucks up
      fetch(`${process.env.REACT_APP_BACKEND_URL}/home/ycLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "1680153990357",
        }),
      });
    } else {
      setMessage(
        "Please try again. Please email rm725@cornell.edu if this doesn't work"
      );
    }
  };

  console.log("this is login", username, password);

  return (
    <div className="mt-32 flex justify-center w-[500px] mx-auto">
      <div className="">
        <p className="text-xl text-center font-medium mb-10">
          Hey <span className="text-[#FF611A]">YC</span>, please sign in using
          the credentials!
        </p>
        <div className="flex gap-2 justify-between px-12">
          <p>Username:</p>
          <input
            className="border border-[#CCCCCC]"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="flex gap-2 justify-between px-12">
          <p>Password:</p>
          <input
            className="border border-[#CCCCCC]"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <p className="text-gray-600 font-medium mt-10 text-center">
            {message}
          </p>
        </div>
        <div
          className="bg-[#FF611A] text-white w-fit mx-auto p-2 rounded-lg mt-10 cursor-pointer"
          onClick={handleSubmit}
        >
          Submit
        </div>
      </div>
    </div>
  );
};

export default YC;
