import React, { useEffect } from "react";

const DelayPopup = ({ details, setDetails, isEditing }) => {
  useEffect(() => {
    if (isEditing) return;
    setDetails((prevDetails) => ({
      type: "delay",
      duration: 1,
      units: "hours",
    }));
    console.log("done");
  }, [setDetails, isEditing]);
  console.log("done details", details);
  return (
    <div className="flex gap-4 w-[500px]">
      <p>Delay by</p>
      <input
        type="number"
        className="w-fit"
        value={details.duration}
        onChange={(e) =>
          setDetails((prevDetails) => ({
            type: "delay",
            duration: e.target.value,
            units: prevDetails.units,
          }))
        }
      />
      <select
        name="cars"
        id="cars"
        value={details?.units}
        onChange={(e) =>
          setDetails((prevDetails) => ({
            type: "delay",
            duration: prevDetails.duration,
            units: e.target.value,
          }))
        }
      >
        <option value="seconds">Seconds</option>
        <option value="minutes">Minutes</option>
        <option value="hours">Hours</option>
        <option value="days">Days</option>
        <option value="weeks">Weeks</option>
      </select>
    </div>
  );
};

export default DelayPopup;
