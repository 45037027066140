import React, { useState, useEffect, useRef } from "react";
import RenderMessageMaster from "./RenderMessageMaster";
import RenderEmail from "./RenderEmail";
import RenderWorkflow from "./RenderWorkflow";
import WorkflowBuilder from "../WorkflowBuilder/WorkflowBuilder";
import SuggestedPanel from "./SuggestedPanel";

const MrPancake = () => {
  const [message, setMessage] = useState("");
  // const [id, setId] = useState();
  const username = JSON.parse(localStorage.getItem("user")).username;
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const sendMessage = async () => {
    console.log("sending message...");
    const updatedConversation = [
      ...conversation,
      { role: "user", content: message },
    ];
    setConversation(updatedConversation);
    setMessage("");
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/mrPancake/sendMessage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // id: id,
          username: username,
          conversation: updatedConversation,
        }),
      }
    );
    const data = await response.json();
    setConversation(data.message.conversation);
    // setId(data.message.conversationId);
    setLoading(false);
    console.log("this is data", data);
  };

  return (
    <div className="flex justify-center">
      <div className="w-[20%] h-[85vh]">
        <SuggestedPanel setMessage={setMessage} />
      </div>
      <div className="h-[85vh] w-[80%]">
        <div className="h-[calc(100%-200px)] border-t-2 border-x-2 rounded-tr-md overflow-y-scroll">
          <RenderMessageMaster
            message={{
              role: "assistant",
              content: "Hi there! I'm Mr. Pancake. How can I help you today?",
            }}
            index={-1}
          />

          {conversation?.map((message, index) => (
            <RenderMessageMaster message={message} index={index} />
          ))}
          {loading && (
            <div className="flex  gap-4 px-5 py-10 bg-gray-100">
              <div className="bg-gray-700 w-6 h-6 relative rounded-lg">
                <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white">
                  P
                </p>
              </div>
              <p className="text-base w-full">
                Working hard to find you an answer...
              </p>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="border-2 rounded-br-md h-[200px] flex flex-col">
          <textarea
            className="h-[75%] w-full pt-2 px-2 focus:outline-0 resize-none text-lg"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="h-[25%] w-full flex justify-end ">
            <p
              className="h-fit bg-beach font-medium px-2 py-1 rounded-lg mr-2 cursor-pointer"
              onClick={sendMessage}
            >
              Send
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MrPancake;
