import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const AddContactPopup = ({
  setShowPopup,
  editContactIndex,
  setEditContactIndex,
  setRefresh,
  emailToSet,
  contact,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState(emailToSet);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [contactId, setContactId] = useState("");

  // useEffect(() => {
  //   if (emailToSet) {
  //     console.log();
  //     setEmail(emailToSet);
  //   }
  // }, [emailToSet]);

  useEffect(() => {
    if (contact) {
      setName(contact.name);
      setEmail(contact.email);
      setPhone(contact.phone);
      setAddress(contact.address);
      setNotes(contact.notes);
      setContactId(contact.id);
    }
  }, [contact]);

  const username = JSON.parse(localStorage.getItem("user")).username;

  const submit = async () => {
    console.log("this is username", username);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/contact/addContact`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          contactId,
          name,
          email,
          phone,
          address,
          notes,
        }),
      }
    );
    const data = await res.json();

    if (data.ok) {
      setShowPopup(false);
      setRefresh((prevRefresh) => !prevRefresh);
      setEditContactIndex(-1);
    }
  };

  return (
    <div>
      <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-20"></div>

      <div className="bg-gray-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
        <div className="flex justify-between items-center bg-gray-200 px-2 py-2 rounded-t-md w-[600px]">
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer invisible"
            onClick={() => {
              setShowPopup(false);
              setEditContactIndex(-1);
            }}
          />
          <p className="font-semibold">
            {editContactIndex > -1 ? "Edit" : "Add a"} contact
          </p>
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer"
            onClick={() => {
              setShowPopup(false);
              setEditContactIndex(-1);
            }}
          />
        </div>
        <div className="flex flex-col gap-0 justify-center px-20 py-10">
          <div>
            <p className="text-base text-primary">Full Name</p>

            <input
              type="text"
              className="border border-gray-300 rounded-lg px-2 py-2 mt-4 w-full"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="John Doe"
            />
          </div>
          <div className="mt-6">
            <p className="text-base text-primary">Email</p>

            <input
              type="text"
              className="border border-gray-300 rounded-lg px-2 py-2 mt-4 w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="hello@gmail.com"
            />
          </div>
          <div className="mt-6">
            <p className="text-base text-primary">Phone</p>

            <input
              type="text"
              className="border border-gray-300 rounded-lg px-2 py-2 mt-4 w-full"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="970-183-4067"
            />
          </div>
          <div className="mt-6">
            <p className="text-base text-primary">Mailing Address</p>

            <input
              type="text"
              className="border border-gray-300 rounded-lg px-2 py-2 mt-4 w-full"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="1234 Main St, Anytown, USA"
            />
          </div>
          <hr className="mt-6" />
          <div className="mt-6">
            <p className="text-base text-primary">Private Notes</p>

            <input
              type="text"
              className="border border-gray-300 rounded-lg px-2 py-2 mt-4 w-full"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Type here"
            />
          </div>

          <div className="self-end flex gap-2">
            <button
              className="hover:bg-gray-100 text-sm font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={() => {
                setShowPopup(false);
                setEditContactIndex(-1);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-brown text-sm hover:bg-beach font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={submit}
            >
              {editContactIndex > -1 ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContactPopup;
