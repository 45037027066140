import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const ContactPopup = ({
  optionsPopupIndex,
  setOptionsPopupIndex,
  project,
  setRefresh,
  currentStage,
}) => {
  const [stage, setStage] = useState();
  const [stages, setStages] = useState();
  const username = JSON.parse(localStorage.getItem("user")).username;
  useEffect(() => {
    async function fetchData() {
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/workflow/getStages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
          }),
        }
      );
      // convert the data to json
      const resultData = await result.json();

      console.log("this is the data", resultData.message);
      setStages(resultData.message);
      if (currentStage === "all") {
        setStage(resultData.message[0].id);
      } else {
        setStage(currentStage);
      }
      console.log("stages", stages);
    }
    fetchData();
  }, []);

  const setContactStage = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/project/setContactStage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          projectId: project.id,
          stageId: stage,
        }),
      }
    );
    const data = await res.json();

    if (data.ok) {
      setOptionsPopupIndex(-1);
      setRefresh((prevRefresh) => !prevRefresh);
    }
  };

  return (
    <div>
      <div className="fixed w-screen h-screen bg-black/25 mx-auto top-0 left-0 z-20"></div>

      <div className="bg-gray-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-lg">
        <div className="flex justify-between items-center bg-gray-200 px-2 py-2 rounded-t-md w-[600px]">
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer invisible"
            onClick={() => setOptionsPopupIndex(-1)}
          />
          <p className="font-semibold">Move project to a different stage</p>
          <CrossIcon
            stroke="black"
            width={35}
            height={35}
            className="float-right cursor-pointer"
            onClick={() => setOptionsPopupIndex(-1)}
          />
        </div>
        <div className="flex flex-col gap-0 justify-center px-20 py-10">
          <p className="text-base text-primary">Select pipeline stage:</p>

          <select
            name="cars"
            id="cars"
            className="mt-2 border-2 rounded-lg py-1 text-base"
            value={stage}
            onChange={(e) => setStage(e.target.value)}
          >
            {stages &&
              stages.map((stage) => (
                <option value={stage.id}>{stage.name}</option>
              ))}
          </select>

          <div className="self-end flex gap-2">
            <button
              className="hover:bg-gray-100 text-sm font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={() => setOptionsPopupIndex(-1)}
            >
              Cancel
            </button>
            <button
              className="bg-brown text-sm hover:bg-beach font-medium text-black rounded-lg w-fit px-4 py-2 mt-8"
              onClick={setContactStage}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPopup;
