import React, { useEffect, useState } from "react";

const ProgressBar = () => {
  const [completed, setCompleted] = useState(0);
  const [loadingInterval, setLoadingInterval] = useState();
  useEffect(() => {
    console.log("using this effect");
    var thisInterval = setInterval(() => {
      setCompleted((prevCompleted) => prevCompleted + 4);
      console.log("interval running");
    }, 1000);
    setLoadingInterval(thisInterval);
  }, []);

  // loading bar never goes over 80
  // if (completed > 80) {
  //   console.log("clearing interval");
  //   clearInterval(loadingInterval);
  // }
  // if (!display) {
  //   console.log("this is not display", !display);
  //   clearInterval(loadingInterval);
  //   return <></>;
  // }
  return (
    <div className="w-[400px] h-6 relative">
      <div className="absolute top-0 w-full h-6 rounded-lg bg-gray-200"></div>
      <div
        className="absolute top-0 h-6 rounded-lg bg-brown hover:bg-beach"
        style={{ width: completed + "%" }}
      ></div>
      <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        {/* Uploading */}
      </p>
    </div>
  );
};

export default ProgressBar;
